/* eslint-disable   no-unused-vars */

import React, { useCallback, useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-solid-svg-icons";

import Footer from "../components/footer/footer.component";

import Header from "../components/header/header.component";
import WhyChooseUs from "../components/sidebar/WhyChooseUs";
import ClientReviews from "../components/sidebar/ClientReviews";

import ThankYouTop from "../components/thankyou/thank-you-top.component";
import ThankYouVideo from "../components/thankyou/thank-you-video.component";
import ThankYouBottom from "../components/thankyou/thank-you-bottom.component";

import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";

import "./ThankYouApply.scss";

const ThankYouApplication = () => {
  return (
    <Container fluid className="themeLayout thankyou">
      <Row className="no-gutter site-header header">
        <Col lg={12}>
          <Header />
        </Col>
      </Row>
      <Row className="no-gutter site-inner">
        <Col className="d-flex justify-content-center" sm={12}>
          <Col
            className="no gutter deluxe-section deluxe-section__alternative_h thank-you-for-applying"
            sm={12}
            md={8}
            lg={8}
          >
            <div className="thank-you-content">
              <ThankYouTop />
              <ThankYouVideo />
              <ThankYouBottom />
            </div>
          </Col>
          <Col  className="d-flex aside-col">
            <div className="thank-you-sidebar">
              <WhyChooseUs />
              <ClientReviews />
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="no-gutter main-footer">
        <Col md={12}>
          <TrustLogosRow />
        </Col>
      </Row>

      <Footer />
    </Container>
  );
};

export default ThankYouApplication;
