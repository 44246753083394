/* eslint-disable   no-unused-vars */
/* eslint-disable   react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import Footer from "../components/footer/footer.component";

import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";

import "./Apply.scss";
import ApplyFormStep1 from "../components/apply/ApplyFormStep1";
import ApplyFormStep2 from "../components/apply/ApplyFormStep2";
import Header from "../components/header/applyheader.component";
import ApplyFooter from "../components/footer/apply-footer";

const ApplyLoan = () => {
  const [debitAmount, setDebitAmount] = useState("");
  const history = useHistory();
  useEffect(() => {
    const debtAmount = history.location.state
      ? history.location.state.debtAmount
      : null;
      console.log('get locationstate', history.location.state);
    if (debtAmount !== null) {
      setDebitAmount(debtAmount);
    }
  }, []);
  const [step, setStep] = useState("1");
  return (
    <Container fluid className="themeLayout apply-new-2" id="apply_page">
      <Row className="no-gutter header site-header applyheader">
        <Col lg={12}>
          <Header />
        </Col>
      </Row>
      <div className="no-gutter site-inner">
        <div className="content-sidebar-wrap">
          <div className="apply_content_wrapper">
            <ApplyFormStep1
              debitAmount={debitAmount}
              setDebitAmount={setDebitAmount}
              setStep={setStep}
              step={step}
            />
            <ApplyFormStep2 debitAmount={debitAmount} step={step} />
          </div>
        </div>
      </div>

      <ApplyFooter></ApplyFooter>
    </Container>
  );
};

export default ApplyLoan;
