/* eslint-disable   no-unused-vars */

import React, { useEffect } from "react";

import "./unbounce-footer.style.scss";
const FreeDebtQuoteFooter = (props) => {
  const getCopyrightYear = () => {
    var year = new Date().getFullYear().toString();
    return year;
  };

  return (
    <>
      <div className="apply-new-footer-wrap unbounce footer-disclaimer">
        <div className="apply-new-footer">
          <div className="privacy">
            <p style={{ lineHeight: "24px", textAlign: "center" }}>
              <span
                style={{
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  fontSize: "10px",
                  color: "rgb(72, 72, 72)",
                  fontStyle: "normal",
                }}
              >
                &copy; 2009- {getCopyrightYear()},&nbsp;National Debt Relief.
              </span>
            </p>
            <p>
              All loans available through National Debt Relief LLC are made by
              lenders in their network and are subject to eligibility criteria
              and review of creditworthiness and history. All loan and rate
              terms are subject to eligibility restrictions, application review,
              credit score, loan amount, loan term, lender approval, and credit
              usage and history. Eligibility for a loan is not guaranteed. Loans
              are not available to residents of all states – please call a
              National Debt Relief representative for further details. Repayment{" "}
              <strong>
                <u>periods range from 24 to 60 months.</u>
              </strong>{" "}
              The range of APRs on loans{" "}
              <strong>is 6.25% to a maximum of 36.00%.</strong> APR. The APR
              calculation includes all applicable fees, including the loan
              origination fee. For Example, a four year $18,000 loan with an
              interest rate of 18.134% and corresponding APR of 21.08% would
              have an estimated monthly payment of $528.79 and a total cost
              payable of $8,281.48.{" "}
              <a href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1250950">
                NMLS #1250950
              </a>
            </p>
            <p style={{ lineHeight: "14px" }}>
              <span style={{ fontSize: "12px" }}>
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
                To help the government fight the funding of terrorism and money
                laundering activities, Federal law requires all financial
                institutions to obtain, verify, and record information that
                identifies each person who opens an account. What this means for
                you: When you open an account, you will be asked for your name,
                address, date of birth, and other information that will allow
                the lender to identify you. You may also be asked to see your
                driver's license or other identifying documents.
              </span>
            </p>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default FreeDebtQuoteFooter;
