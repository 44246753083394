import React from "react";

import { Container } from "react-bootstrap";

import ReviewIcon from "../review-icon/review-icon.component";

import "./trust-logos-row.style.scss";

const TrustLogosRow = () => {
  const logos = [
    {
      name: "BestCompany",
      url: "https://bestcompany.com/debt-relief/company/national-debt-relief",
    }, {
      name: "Google",
      url: "https://g.page/NationalDebtRelief?gm",
    }, {
      name: "BBB",
      url:
        "https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899",
    },
  ];

  return (
    <Container>
      <div className="trust-icon-container">
        {logos.map(({ name, url }, key) => (
          <a
            key={key}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`col_${key + 1} trust-logo-content`}
          >
            <div className="logo">
              <ReviewIcon name={name} />
            </div>
          </a>
        ))}
      </div>
    </Container>
  );
};

export default TrustLogosRow;
