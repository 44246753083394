/* eslint-disable   no-unused-vars */

/* eslint-disable    eqeqeq */
/* eslint-disable    jsx-a11y/anchor-has-content */
/* eslint-disable     jsx-a11y/anchor-is-valid */
/* eslint-disable     jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Container, Row, Col } from "react-bootstrap";

import Footer from "../components/footer/footer.component";
import chevronIcon from "../assets/img/chevron-right-solid-01.png";

import Header from "../components/header/header.component";
import { useHistory } from "react-router-dom";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";

import "./Apply.scss";
import "./_financial_calculator.scss";

const CostofDebtCalculator = () => {
  const [debtAmount, setDebtAmount] = useState("");

  
  const history = useHistory();
  const [step, setStep] = useState("1");

  const onCheckNow = (event) => {
    event.preventDefault();
    let debtAmount = event.target.debtAmount.value;
    if (debtAmount != " ") {
      history.push(`/apply-loan?debt_amount=${debtAmount}`, {
        debtAmount: debtAmount,
      });
    } else if (debtAmount == " ") {
      event.target.debtAmount.classList.add("invalid");
    }
  };




  return (
    <Container
      fluid
      className="themeLayout full-width-content financial-calculator"
    >
      <Row className="no-gutter site-header header">
        <Col lg={12}>
          <Header />
        </Col>
      </Row>
     
      <div className="no-gutter site-inner">
        <div className="content-sidebar-wrap">
          <div className="content">
            <header class="entry-header">
              <h1 className="entry-title">Cost of Debt Calculator</h1>
            </header>
            <div className="entry-content">
              {" "}
              <p>
                It makes sense to think that the cost of debt is simply the
                amount of debt that you owe, right? But this is most often not
                the case. Unless you paid extra close attention and were
                extremely diligent, it’s likely that your debt or loan involves
                interest collected over time. Until you calculate what that
                interest will amount to by the time you finish paying off your
                debt, you won’t know what your debt is really costing you. Use
                this <em>Cost of Debt Calculator</em> to determine exactly how
                much you’ll be paying over time.
              </p>
            </div>

            <a href="#KJEMainContent" class="KJEAccessibleSkip">
              [Skip to Content]
            </a>
            <div class="KJEWrapper">
              <div class="KJEWidthConstraint">
                <div class="KJELogo">
                  <img
                    src="logo.png"
                    class="KJELogo"
                    alt="National Debt Relief LLC"
                  />
                </div>

                <h1 class="KJEFontTitle">Cost-of-Debt Calculator</h1>
                <a id="KJEMainContent" title="page content"></a>

                <span id="KJECalculatorDescID">
                  The interest you pay on your debt can quickly become very
                  expensive. Use this calculator to help determine just how
                  expensive your debt has become. Enter all of your credit cards
                  and outstanding installment loan balances. Find out how much
                  you owe, how much interest you are scheduled to pay and how
                  long it will take to pay it all off.
                </span>
                <noscript>
                  <div class="KJECenterText">
                    <div id="KJENoJavaScript" class="KJENoJavaScript">
                      Javascript is required for this calculator. If you are
                      using Internet Explorer, you may need to select to 'Allow
                      Blocked Content' to view this calculator.
                      <p>
                        For more information about these financial calculators
                        please visit:{" "}
                        <a href="https://www.dinkytown.net">Dinkytown.net</a>{" "}
                        Financial Calculators from KJE Computer Solutions, Inc.
                      </p>
                    </div>
                  </div>
                </noscript>
                <div id="KJEAllContent"></div>
                <link
                  type="text/css"
                  rel="StyleSheet"
                  href="https://ndr-web-static.s3.amazonaws.com/static/calculators/KJE.css"
                />
                <link
                  type="text/css"
                  rel="StyleSheet"
                  href="https://ndr-web-static.s3.amazonaws.com/static/calculators/KJESiteSpecific.css"
                />
                <div id="KJEAllContent"></div>
          
                <hr class="KJEFooterLine" />
                <div class="KJECenterText">
                  <p>
                    <span class="KJEBold">National Debt Relief LLC</span>
                    <br />
                    180 Maiden Lane, 30th Floor
                    <br />
                    New York, NY
                  </p>
                  <p class="KJEFooter">
                    Information and interactive calculators are made available
                    to you only as self-help tools for your independent use and
                    are not intended to provide investment or tax advice. We
                    cannot and do not guarantee their applicability or accuracy
                    in regards to your individual circumstances. All examples
                    are hypothetical and are for illustrative purposes. We
                    encourage you to seek personalized advice from qualified
                    professionals regarding all personal finance issues.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="hjs-calculator-area" style={{ marginTop: "30px" }}>
              <p>
                To make the most out of this calculator, simply input your
                information for each and every credit card or account you hold
                with debt. Make sure you have the current balance and interest
                rate available for each. If you’re not sure, check your online
                account, monthly statements, or even call their customer service
                department for more information. If you have an auto loan,
                personal loan, or real-estate loan, you’ll be able to input this
                information as well. If your debt were to be paid off in full
                immediately, you’d simply owe the balances on each account.
                Unfortunately, most Americans today operate on a system of
                credit. Many people can’t afford to pay for a car or home
                upfront in cash, so they take out a loan where they end up owing
                the creditor their original balance, plus accrued interest over
                the lifetime of the loan.
              </p>
              <p>
                To make the most out of this calculator, simply input your
                information for each and every credit card or account you hold
                with debt. Make sure you have the current balance and interest
                rate available for each. If you’re not sure, check your online
                account, monthly statements, or even call their customer service
                department for more information. If you have an auto loan,
                personal loan, or real-estate loan, you’ll be able to input this
                information as well. If your debt were to be paid off in full
                immediately, you’d simply owe the balances on each account.
                Unfortunately, most Americans today operate on a system of
                credit. Many people can’t afford to pay for a car or home
                upfront in cash, so they take out a loan where they end up owing
                the creditor their original balance, plus accrued interest over
                the lifetime of the loan.
              </p>
            </div>
          </div>

          <div className="after-content-form">
            <form id="do--you-qualify-form" onSubmit={onCheckNow}>
              <h3 class="widget-title ">
                Do you qualify for debt consolidation?
              </h3>
              <div className="apply_form_fields_wrapper">
                <div className="select-wrapper">
                  <select
                    name="debtAmount"
                    className="get-started-form-input "
                    id="id_debt_amount"
                  >
                    <option value=" " defaultValue="1">
                      Your Debt Amount
                    </option>

                    <option value="$0 - $4,999">$0 - $4,999</option>

                    <option value="$5,000 - $7,499">$5,000 - $7,499</option>

                    <option value="$7,500 - $9,999">$7,500 - $9,999</option>

                    <option value="$10,000 - $14,999">$10,000 - $14,999</option>

                    <option value="$15,000 - $19,999">$15,000 - $19,999</option>

                    <option value="$20,000 - $29,999">$20,000 - $29,999</option>

                    <option value="$30,000 - $39,999">$30,000 - $39,999</option>

                    <option value="$40,000 - $49,999">$40,000 - $49,999</option>

                    <option value="$50,000 - $59,999">$50,000 - $59,999</option>

                    <option value="$60,000 - $69,99">$60,000 - $69,999</option>

                    <option value="$70,000 - $79,999">$70,000 - $79,999</option>

                    <option value="$80,000 - $89,999">$80,000 - $89,999</option>

                    <option value="$90,000 - $99,999">$90,000 - $99,999</option>

                    <option value="$100,000+">$100,000+</option>
                  </select>
                  <div className="do-you-qualify-button">
                    <button type="submit" className="button">
                      Check Now
                      <span className="fa fa-chevron-right" aria-hidden="true">
                        <img src={chevronIcon} />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Row className="no-gutter main-footer">
        <Col md={12}>
          <TrustLogosRow />
        </Col>
      </Row>

      <Footer />
    </Container>
  );
};

export default CostofDebtCalculator;
