/* eslint-disable  no-empty-pattern */
import React from "react";
import { useHistory } from "react-router-dom";

const ThankYouTop= ({}) => {
  const history = useHistory();
  const firstName = history.location.state?history.location.state.firstName:null;
    return (
        <div className="thank-you-top">
        <h1>Congratulations!</h1>
        <p>Dear<span> {firstName},</span></p> 
        <p>
          You've just taken the first step to get free from your debt!
          One of our certified debt counselors will be in touch shortly.{" "}
          <strong>
            Please expect our call from a Toll-Free number.
          </strong>{" "}
          We'll answer any questions you have. For immediate assistance,
          call us now to speak with a counselor during our extended
          business hours. Monday – Friday: 8:00am – Midnight ET and
          Saturday: 10:00am – 10:00pm ET. You can get a head start by
          calling the toll-free number below.
        </p>
        <p className="green-phone">
          <a href="tel:8003009550">800-300-9550</a>
        </p>
      </div>

     
    );
  };
  
  export default ThankYouTop;
  