/* eslint-disable       eqeqeq */
/* eslint-disable        array-callback-return */
import axios from "axios";

import moment from "moment";

const fetchReviews = async ({ businessId, filter }) => {
  const now = new Date();

  const dateRange = moment(now.setDate(now.getDate() - 90)).format(
    "MM/DD/YYYY"
  );

  let sources = [
    "Google",
    "BBB",
    "Trustpilot",
    "Consumer Affairs",
    "BestCompany",
  ];

  try {
    const options = {
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      data: {
        fromDate: dateRange,
        ratings: [4, 5],
        sources,
        toDate: moment().format("MM/DD/YYYY"),
      },
      url: `https://api.birdeye.com/resources/v1/review/businessId/${businessId}?sindex=1&api_key=${process.env.REACT_APP_BIRDEYE_API_KEY}`,
    };

    const response = await axios(options);

    const { data } = response;

    let reviews = data;
    let reviewsArray = [...reviews];
    //console.log('ndr debug - original reviews', JSON.stringify(reviewsArray));
    var filteredReviews = reviewsArray.filter(function (review) {
      if (review.comments !== null) {
        if (
          review.comments.includes("debt relief") == true
        ) {
          if ((review.comments.includes("negotiation") == false) &&
          (review.comments.includes("settlement") == false) && (review.comments.includes("National Debt Relief") == true)) {
            return review;
          }
        }
        else if((review.comments.includes("debt relief") == false)&&(review.comments.includes("negotiation") == false) &&
        (review.comments.includes("settlement") == false)) {
          return review
        }
      }
      else{
        return review;
      }
    });


    if (filter === "company") {
      filteredReviews = filteredReviews.sort(
        ({ sourceType: sourceTypeA }, { sourceType: sourceTypeB }) => {
          return sourceTypeA < sourceTypeB
            ? -1
            : sourceTypeA > sourceTypeB
            ? 1
            : 0;
        }
      );
    }
    if((filter === "BBB")||(filter === "BestCompany")||(filter === "Consumer Affairs") || (filter==="Google")||(filter ==="Trustpilot")){

      filteredReviews = filteredReviews.filter(filteredReview=>{ return filteredReview.sourceType.includes(filter)})

    }

  
    //console.log('ndr debug - filtered reviews', JSON.stringify(filteredReviews));

    return filteredReviews;
  } catch (e) {
    return new Error(e);
  }
};

export default fetchReviews;
