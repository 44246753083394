
/* eslint-disable   no-unused-vars */

/* eslint-disable   react/jsx-no-duplicate-props */
/* eslint-disable     jsx-a11y/anchor-has-content*/
/* eslint-disable  react/jsx-no-target-blank */
import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import {getParams} from "../../utils/getParams";
import "./tyheader.style.scss";
import "./applyheader.style.scss";
import "./getoutofodebtheader.style.scss";
const  GetOutofDebtHeader = (props) => {
    const history = useHistory();
    
    const params = getParams(window.location.href);
  console.log('test params', params);
   return (
    <nav className="navigation getoutofdebtloanheader navbar navbar-expand-lg">
    <Row className="no-gutter navigation-wrapper" >
    <Col sm={8} md={8} lg={8} className="no gutter">
    <Navbar.Brand><a href="/"><img className="img-fluid" src={Logo} alt="" /></a></Navbar.Brand></Col>
    <Col sm={4} md={4} sm={4} lg={4} className="applyLink-col" >
    <div className="applyWidgetRight">
    <a class="gold-award" href="https://www.toptenreviews.com/best-debt-consolidation-companies" target="_blank"></a>
    <FontAwesomeIcon size="lg" color="#868686" icon={faMobileAlt} />
    </div>

    <div className="applyLink">  
    <p class="call-now">Talk to a loan counselor toll free</p>
    <a  href="tel:8003009550" className="header-phone  " >
<span>800-300-9550</span></a></div>
</Col></Row>
  </nav>
  );
}

export default GetOutofDebtHeader;
