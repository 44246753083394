/* eslint-disable   no-unused-vars */
import axios from "axios";

import moment from "moment";

const fetchWPContent = async ( page ) => {


  try {
    

    const options = {
      method: "GET",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      data: {
      slug:'debt-consolidation-loan-savings'
      },
      url: `https://www.nationaldebtrelief.com/wp-json/wp/v2/pages/?slug=${page}`,
    };

    const response = await axios(options);

    const { data } = response;
   

    let wpdata = {
        title:data[0].title.rendered,
        render:data[0].content.rendered};
        console.log('check wp data', wpdata)
    return wpdata;
  } catch (e) {
    return new Error(e);
  }
};

export default fetchWPContent;
