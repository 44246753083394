/* eslint-disable   no-unused-vars */
/* eslint-disable   react/jsx-no-target-blank */
import React, { useEffect } from "react";

import "./unbounce-footer.style.scss";
const UnbounceFooter = (props) => {
  const getCopyrightYear = () => {
    var year = new Date().getFullYear().toString();
    return year;
  };

  return (
    <>
      <div className="apply-new-footer-wrap unbounce">
        <div className="apply-new-footer">
          <div className="cred">
            <a
              target="_blank"
              href="https://www.topconsumerreviews.com/debt-relief-programs/"
              rel="nofollow"
            >
              <img
                src="https://www.nationaldebtrelief.com/wp-content/uploads/2018/01/2018-TCR-Blue-Ribbon-Graphic.png"
                alt="top-consumer-reviews-2018-small"
                width="130"
                height="74"
                className="alignnone size-full"
              />
            </a>

            <a
              title="National Debt Relief, LLC BBB Business Review"
              target="_blank"
              href="https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899#bbbonlineclick"
            >
              <img
                alt="National Debt Relief, LLC BBB Business Review"
                src="https://www.nationaldebtrelief.com/wp-content/uploads/2015/07/bbb-logo-alt1.png"
              />
            </a>
            <div
              className="trustedsite-trustmark"
              data-type="202"
              data-width="180"
              data-height="75"
            ></div>
          </div>

          <div className="privacy">
            <center style={{ marginBottom: "20px" }}>
              <a href="/privacypolicy">
                Privacy Policy
              </a>{" "}
              |{" "}
              <a href="/termsofsite/">
                Terms Of Site
              </a>
            </center>
            <p style={{ lineHeight: "14px" }}>
              {" "}
              <span style={{ fontSize: "12px" }}>
                All loans available through National Debt Relief LLC are made by
                lenders in their network and are subject to eligibility criteria
                and review of creditworthiness and history. All loan and rate
                terms are subject to eligibility restrictions, application
                review, credit score, loan amount, loan term, lender approval,
                and credit usage and history. Eligibility for a loan is not
                guaranteed. Loans are not available to residents of all states -
                please call a National Debt Relief representative for further
                details. Repayment{" "}
                <strong>
                  <u>periods range from 24 to 60 months.</u>
                </strong>{" "}
                The range of APRs on loans{" "}
                <strong>is 6.25% to a maximum of 35.99%.</strong> APR. The APR
                calculation includes all applicable fees, including the loan
                origination fee. For Example, a four year $18,000 loan with an
                interest rate of 18.134% and corresponding APR of 21.08% would
                have an estimated monthly payment of $528.79 and a total cost
                payable of $8,281.48.{" "}
                <a
                  data-action="url"
                  data-params="false"
                  href="clkn/https/www.nationaldebtrelief.com/NMLS"
                  target="_blank"
                >
                  <span style={{ fontSize: "12px" }}>NMLS #1250950</span>
                </a>
              </span>
            </p>
            <p style={{ lineHeight: "14px" }}>
              <span style={{ fontSize: "12px" }}>
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
                To help the government fight the funding of terrorism and money
                laundering activities, Federal law requires all financial
                institutions to obtain, verify, and record information that
                identifies each person who opens an account. What this means for
                you: When you open an account, you will be asked for your name,
                address, date of birth, and other information that will allow
                the lender to identify you. You may also be asked to see your
                driver's license or other identifying documents.
              </span>
            </p>
            <p style={{ lineHeight: "24px", textAlign: "center" }}>
              <span
                style={{
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  color: "rgb(72, 72, 72)",
                  fontStyle: "normal",
                }}
              >
                Copyright &copy; {getCopyrightYear()} National Debt Relief,
                LLC&nbsp;
              </span>
            </p>
            <p style={{ lineHeight: "24px", textAlign: "center" }}>
              <span
                style={{
                  fontWeight: "400",
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  color: "rgb(72, 72, 72)",
                  fontStyle: "normal",
                }}
              >
                180 Maiden Lane, 30th Floor, New York, NY 10038 All Rights
                Reserved.
              </span>
            </p>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default UnbounceFooter;
