import React from "react";
const ThankYouBottomLoan= () => {

    return (
        <div className="thank-you-bottom">
              <h2>How the Process Works</h2>
              <ol className="repayment-list">
                <li className="one">
                  <p>
                    <span className="list-highlight">
                    Answer a few quick questions to complete the pre-qualification process.
                    </span>{" "}
                    Your specialist will work to assess your current debts, review your current financial solution and determine which debt consolidation options may be available to you.
                  </p>
                </li>
                <li className="two">
                  <p>
                    <span className="list-highlight">
                    Review debt consolidation options

                    </span>{" "}
                    If you pre-qualify for a loan, a debt specialist will review the options available to you. You can review the loan amounts, interest rates, and terms to see if you will save money against your current debts. Alternatively, you will learn about other options available to achieve your goal of becoming debt free.


                  </p>
                </li>
                <li className="three">
                  <p>
                    <span className="list-highlight">
                    Complete the loan application
                    </span>{" "}
                    If you pre-qualify and would like to move forward with a loan, you will to need to complete an application with a lender in our network and provide the appropriate documents. Your representative will be able to assist you in the process.


                  </p>
                </li>
                <li className="four">
                  <p>
                    <span className="list-highlight">
                    Secure Loan Funding and Pay Off Your Debt
                    </span>{" "}
                    If your loan is approved, the funds will be made available to pay off the selected debts that you wanted to consolidate. Funds will go directly to your creditors.


                  </p>
                </li>
              </ol>
             
              </div>
        
     
    );
  };
  
  export default ThankYouBottomLoan;
  