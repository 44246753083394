/* eslint-disable   react/jsx-no-target-blank */
/* eslint-disable   jsx-a11y/alt-text */
/* eslint-disable   no-unused-vars */
import React from "react";

import { Container } from "react-bootstrap";

import ReviewIcon from "../review-icon/review-icon.component";

import "../trust-logos-row/trust-logos-row.style.scss";
import "./freedebtquotefooter.style.scss";

const FreeDebtTrustLogosRow = () => {
  /* const logos = [
    {
      name: "National Debt Relief, LLC BBB Business Review",
      url: "https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899#bbbonlineclick",
    }, 
    {
      name: "AFCC",
      url:
        "http://members.americanfaircreditcouncil.org/list/national-debt-relief-llc/",
    }, 
    {
      name: "Top 10 Reviews",
      url: "https://www.toptenreviews.com/best-debt-settlement-companies",
    }, 
    {
      name: "Google",
      url: "https://g.page/NationalDebtRelief?gm",
    }, 
    {
      name: "Top Consumer Reviews",
      url:
        "https://www.topconsumerreviews.com/debt-relief-programs/",
    },
    {
        name: "ConsumerAdvocate",
        url:
          "https://www.consumersadvocate.org/debt-consolidation",
      },
    {
        name: "Trustpilot",
        url: "https://www.trustpilot.com/review/www.nationaldebtrelief.com",
        image: "https://www.nationaldebtrelief.com/wp-content/uploads/2013/07/bbb-large.png",
        width:"187",
        height:"71"
      }
    
  ];
*/
  return (
    <>
      <div className="hjs-section footer-logos">
        <div className="wrap">
          <div class="logos-wrap">
            <div class="footer-logo ">
              <a
                href="https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899#bbbonlineclick"
                target="_blank"
              >
                <img
                  width="187"
                  height="71"
                  src="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2013/07/bbb-large.png"
                  class="attachment-full size-full"
                  alt=""
                  loading="lazy"
                />
              </a>
            </div>
            <div class="footer-logo ">
              <a
                href="http://members.americanfaircreditcouncil.org/list/national-debt-relief-llc/"
                target="_blank"
              >
                <img
                  width="80"
                  height="80"
                  src="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2015/06/new-afcc-801.png"
                  class="attachment-full size-full"
                  alt=""
                  loading="lazy"
                />
              </a>
            </div>
            <div class="footer-logo ">
              <a
                href="https://www.toptenreviews.com/best-debt-settlement-companies"
                target="_blank"
              >
                <img
                  width="46"
                  height="74"
                  src="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2021/01/TopTenReviews-2021-Best-Overall.png"
                  class="attachment-full size-full"
                  alt=""
                  loading="lazy"
                />
              </a>
            </div>
            <div class="footer-logo ">
              <a
                href="https://www.topconsumerreviews.com/debt-relief-programs/"
                target="_blank"
              >
                <img
                  width="130"
                  height="76"
                  src="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2021/01/2021-TopConsumerReviews-Top-Pick.png"
                  class="attachment-full size-full"
                  alt=""
                  loading="lazy"
                />
              </a>
            </div>
            <div class="footer-logo ">
              <a
                href="https://www.consumersadvocate.org/debt-consolidation"
                target="_blank"
              >
                <img
                  width="500"
                  height="360"
                  src="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2019/07/Consumer-Affairs-Top-Rated.png"
                  class="attachment-full size-full"
                  alt=""
                  loading="lazy"
                  srcset="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2019/07/Consumer-Affairs-Top-Rated.png 500w, https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2019/07/Consumer-Affairs-Top-Rated-300x216.png 300w"
                  sizes="(max-width: 500px) 100vw, 500px"
                />
              </a>
            </div>
            <div class="footer-logo tp">
              <a
                href="https://www.trustpilot.com/review/www.nationaldebtrelief.com"
                target="_blank"
              >
                <img
                  width="288"
                  height="72"
                  src="https://gutenberg.nationaldebtrelief.com/wp-content/uploads/2018/08/trustpilot-logo-288.png"
                  class="attachment-full size-full"
                  alt="trustpilot-logo"
                  loading="lazy"
                />
              </a>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default FreeDebtTrustLogosRow;
