
/* eslint-disable   react/jsx-no-target-blank */
import React from "react";
const ThankYouBottom= () => {

    return (
        <div className="thank-you-bottom">
              <h2>How the Process Works</h2>
              <ol className="repayment-list">
                <li className="one">
                  <p>
                    <span className="list-highlight">
                      Complete your Free Debt Analysis and discuss your
                      financial situation
                    </span>{" "}
                    Your debt counselor will work with you to assess your debt,
                    review your budget, and determine what debt relief options
                    are available to you.
                  </p>
                </li>
                <li className="two">
                  <p>
                    <span className="list-highlight">
                      Estimate your monthly payment with a debt consolidation
                      program
                    </span>{" "}
                    At the end of your Debt Analysis if you qualify for a debt
                    consolidation program, your counselor will provide the real
                    dollar amount you would pay each month if you enroll. You
                    can enroll right then or take time think about it-no
                    limited-time offers!
                  </p>
                </li>
                <li className="three">
                  <p>
                    <span className="list-highlight">
                      Start the program and pay just one payment each month
                    </span>{" "}
                    You pay us one monthly payment and we negotiate and pay your
                    creditors on your behalf. This new monthly payment is often
                    lower than your current credit card payments so you get more
                    breathing room.
                  </p>
                </li>
                <li className="four">
                  <p>
                    <span className="list-highlight">
                      Be debt-free faster than you think
                    </span>{" "}
                    If you are behind in your payments, most creditors will
                    agree to negotiate. Most clients are completely debt-free in
                    24-48 payments. That's 2-4 years to pay off ALL of your
                    credit card debt, not 10-20!
                  </p>
                </li>
              </ol>
              <h2>Download 2 free ebooks to help with your finances:</h2>
              <div className="free-book-wrap">
                <div className="free-book-one">
                  <center>
                    <a
                      href="https://ndr-web-static.s3.amazonaws.com/static/ndr_app/pdf/101-Ways-Raise-Emergency-Cash.pdf"
                      target="_blank"
                      className="image-link"
                    >
                      <img
                        className="aligncenter size-full"
                        src="https://ndr-web-static.s3.amazonaws.com/static/ndr_app/images/101-ways-raise-emergency-cash.png"
                        alt="101 Ways Raise Emergency Cash"
                        width="109"
                        height="175"
                      />
                    </a>
                  </center>
                  <center>
                    <strong>
                      <a
                        className="book-download-link"
                        href="/static/ndr_app/pdf/101-Ways-Raise-Emergency-Cash.pdf"
                        target="_blank"
                      >
                        Download Now »
                      </a>
                    </strong>
                  </center>
                </div>
                <div className="free-book-two">
                  <center>
                    <a
                      href="https://ndr-web-static.s3.amazonaws.com/static/ndr_app/pdf/FamilyBudget.pdf"
                      target="_blank"
                      className="image-link"
                    >
                      {" "}
                      <img
                        className="aligncenter size-full"
                        src="https://ndr-web-static.s3.amazonaws.com/static/ndr_app/images/make-a-family-budget.png"
                        alt="How To Make a Family Budget"
                        width="109"
                        height="175"
                      />{" "}
                    </a>
                  </center>
                  <center>
                    <strong>
                      <a
                        className="book-download-link"
                        href="https://ndr-web-static.s3.amazonaws.com/static/ndr_app/pdf/FamilyBudget.pdf"
                        target="_blank"
                      >
                        Download Now »
                      </a>
                    </strong>{" "}
                  </center>
                </div>
              </div>
              </div>
        
     
    );
  };
  
  export default ThankYouBottom;
  