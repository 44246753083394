import Arrow from "./arrow.png";
import Quote from "./quote.png";

import Accolade1 from "./bbb-vertical-logo.png";
import Accolade2 from "./great_place_work.png";
import Accolade3 from "./toptenreview_2020.png";
import Accolade4 from "./top_consumer_reviews_2020.png";
import Accolade5 from "./Consumer-Affairs-Top-Rated.png";
import Accolade6 from "./trustpilot.png";

import Facebook from "./facebook.png";
import Linkedin from "./linkedin.png";
import Twitter from "./twitter.png";
import Instagram from "./instagram.png";
import Youtube from "./you-tube.png";

import PlayButton from "./play_btn.png";

import HeaderImage from "./salesrep.jpeg";

export const Images = {
	header: { src: HeaderImage, href: null },
	arrow: { src: Arrow, href: null },
	quote: { src: Quote, href: null },
	accolades: [
		{
			src: "https://seal-newyork.bbb.org/seals/blue-seal-250-52-national-debt-relief-llc-110899.png",
			href: "https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899#bbbonlineclick",
			srcMobile: Accolade1,
      		hrefMobile: 'https://www.bbb.org/new-york-city/business-reviews/credit-and-debt-counseling/national-debt-relief-llc-in-new-york-ny-110899/'
		},
		{
			src: Accolade2,
			href: "https://www.greatplacetowork.com/certified-company/5003115",
		},
		{
			src: Accolade3,
			href: "https://www.toptenreviews.com/best-debt-settlement-companies"
		},
		{
			src: Accolade4,
			href: "https://www.topconsumerreviews.com/debt-relief-programs/?_gl=1*1en4ipz*_gcl_aw*R0NMLjE1ODAzMzI4NjYuQ2owS0NRaUEwNFh4QlJENUFSSXNBR0Z5Z2o5eW9ONENaaWxhWHViS3dVZ0xMcUhpZVFfVHd4bmxrVmg2SC04bVVXRjZOLUZFTWFkS1lUa2FBbktqRUFMd193Y0I.",
		},
		{
			src: Accolade5,
			href: "https://www.consumersadvocate.org/debt-consolidation?_gl=1*1en4ipz*_gcl_aw*R0NMLjE1ODAzMzI4NjYuQ2owS0NRaUEwNFh4QlJENUFSSXNBR0Z5Z2o5eW9ONENaaWxhWHViS3dVZ0xMcUhpZVFfVHd4bmxrVmg2SC04bVVXRjZOLUZFTWFkS1lUa2FBbktqRUFMd193Y0I.",
		},
		{
			src: Accolade6,
			href: "https://www.trustpilot.com/review/www.nationaldebtrelief.com",
		},
	],
	socialMedia: [
		{ src: Facebook, href: "https://www.facebook.com/NationalDebtRelief/" },
		{ src: Linkedin, href: "https://www.linkedin.com/company/national-debt-relief-group/" },
		{ src: Twitter, href: "https://twitter.com/NationalRelief_" },
		{ src: Instagram, href: "https://www.instagram.com/nationaldebtrelief" },
		{ src: Youtube, href: "https://www.youtube.com/user/NationalDebtReliefCo" }
	],
	PlayButton
};
