/* eslint-disable   eqeqeq */
import React from "react";

import PropTypes from "prop-types";

import { Pagination } from "react-bootstrap";

import ReviewListItem from "../review-list-item/review-list-item.component";

import "./review-list.style.scss";

const ReviewList = ({
  currentPageNumber,
  filteredReviews,
  handleFirstClick,
  handleItemClick,
  handleLastClick,
  handleNextClick,
  handlePrevClick,
  reviews, 
  isLoading
}) => {
  const pagesArray = [];

  const perPage = 10;

  const totalNumberPages = Math.ceil(reviews.length / perPage);


  for (let i = 0; i < totalNumberPages; i++) {
    pagesArray.push(i + 1);
  }

  const lastPage = pagesArray[pagesArray.length - 1];

  const calculatePrevPageToShow =
    currentPageNumber > 4 ? currentPageNumber - 3 : currentPageNumber - 2;

  const calculate = currentPageNumber === 1 ? 1 : calculatePrevPageToShow;

  const updatedPagesArray = pagesArray?.slice(calculate, currentPageNumber + 2);

  const firstIndex = updatedPagesArray.indexOf(1);

  const lastIndex = updatedPagesArray.indexOf(lastPage);

  if (firstIndex > -1) updatedPagesArray.splice(firstIndex, 1);

  if (lastIndex > -1) updatedPagesArray.splice(lastIndex, 1);


  return (
    <div className="list">
      {(filteredReviews.length > 0 && isLoading == false )&& filteredReviews.map((review) => (
          <ReviewListItem key={review?.reviewId} review={review} />
        ))}
  
      {isLoading == true && <div className="loader"></div>}
      {(filteredReviews.length == 0 && isLoading ==false) && <div className="pagination-container"></div>}

      <div className="pagination-container">
        {(filteredReviews.length > 0 && isLoading==false) && (
          <Pagination size="sm">
            <Pagination.First onClick={handleFirstClick} />
            <Pagination.Prev disabled={currentPageNumber === 1 && true} onClick={handlePrevClick} />

            <Pagination.Item
              active={currentPageNumber === 1}
              onClick={() => handleItemClick(1)}
            >
              {1}
            </Pagination.Item>

            {currentPageNumber >= 4 && <Pagination.Ellipsis />}

            {pagesArray.length > 2 && updatedPagesArray.map((pageNumber, key) => (
              <Pagination.Item
                active={pageNumber === currentPageNumber}
                key={key}
                onClick={() => handleItemClick(pageNumber)}
              >
                {pageNumber}
              </Pagination.Item>
            ))}

            {currentPageNumber < lastPage - 2 && <Pagination.Ellipsis />}

              <Pagination.Item
                active={currentPageNumber === lastPage}
                onClick={() => handleItemClick(lastPage)}
              >
                {lastPage}
              </Pagination.Item>

            <Pagination.Next disabled={currentPageNumber === lastPage && true} onClick={handleNextClick} />
            <Pagination.Last onClick={() => handleLastClick(lastPage)} />
          </Pagination>
        )}
      </div>
    </div>
  );
};

ReviewList.defaultProps = {
  reviews: null,
};

ReviewList.propTypes = {
  reviews: PropTypes.array.isRequired,
};

export default ReviewList;
