/* eslint-disable   no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header/header.component";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import Footer from "../components/footer/footer.component";
import "./Template.scss";
import "./Unbounce.scss";
import UnbounceHeader from "../components/header/unbounceheader.component";
import UnbounceFooter from "../components/footer/unbounce-footer.component";
const AboutUs = () => {
  return (
    <Container fluid className="unbounce themeLayout full-width-content">
      <Row className="no-gutter header">
        <Col sm={12}>
          <UnbounceHeader />
        </Col>
      </Row>
      <div className="no-gutter site-inner">
        <div className="content-sidebar-wrap">
          <div className="content">
            <div className="  article entry ">
              <div className="lp-pob-box">
                <div className="color-overlay">
                  <div
                    className="lp-pom-text title_secondary"
                   
                  >
                    <h1
                      className=""
                      >
                    
                   
                      Why National Debt Relief?

                      
                    </h1>
                  </div>
                  <div className="lp-pom-text main-text" >
                    <p style={{ lineHeight: "20px", textAlign: "center" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          color: "rgb(39, 39, 39)",
                          fontStyle: "normal",
                        }}
                      >
           In the decade since we were founded, we’ve come to pride ourselves on our world class service and excellent results we’re able to achieve for our clients. Every day we’re able to help more and more people escape from the overwhelming burden of debt. Give us a call today, we have certified debt specialists standing by, ready to review your debt assessment and create a customized debt consolidation plan.
                      </span>
                    </p>
                  </div>
                  <a
                    className=" lp-pom-button"
                    id="lp-pom-button-63"
                    href="/apply-loan/"
                    target="_self"
                    data-params="true"
                  >
                    <span className="label">Get Your Free Debt Assessment</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    <UnbounceFooter></UnbounceFooter>
    </Container>
  );
};
export default AboutUs;
