
/* eslint-disable   no-unused-vars */


import React from "react";
import {Navbar, Row, Col} from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

import {getParams} from "../../utils/getParams";
import "./tyheader.style.scss";
import "./applyheader.style.scss";
const Header = (props) => {
  const history = useHistory();

  const params = getParams(window.location.href);
  const src = params.src;
  console.log('test params', params);
  return (
    <nav className="navigation navbar navbar-expand-lg ">
      <Row className="no-gutter navigation-wrapper" >
        <Col sm={6} md={6} lg={6} className="no gutter">
          <Navbar.Brand><a href="/"><img className="img-fluid" src={Logo} alt="" /></a></Navbar.Brand>
        </Col>
        <Col sm={6} md={6} lg={6} className="applyLink-col widget-area header-widget-area" >
          <div className="widget widget_execphp phone-widget">
            <section className="widget widget_execphp phone-widget">
              <div className="widget-left">

              </div><div className="widget-right">
                <p>Talk to a debt counselor toll free:</p>
                <p className="phone">
                  <span className="icon-wrap">
                    <FontAwesomeIcon size="s" icon={faPhone} />
                  </span>

                  <span className="phone-text">
                    <a href="tel:8003009550">800-300-9550</a>
                  </span>
                </p>
              </div>
            </section>
          </div>
          {/* <div className="applyLinkmobile">
            <img src="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png" alt="ndr-google-reviews" loading="lazy" data-src-mobile-1x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png" data-src-mobile-2x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_105e02e000000000000028.png" data-src-mobile-3x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_1000000000000000000028.png" title="ndr-google-reviews" srcset="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_105e02e000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_1000000000000000000028.png 3x" />
          </div> */}
        </Col>
      </Row>

      {/* <Row className="no-gutter navigation-wrapper widget phone-widget" >
        <div className="widget-right">

          <p className="phone">
            <span className="icon-wrap">
              <FontAwesomeIcon size="xs" icon={faPhone} />
            </span>

            <span className="phone-text">
              <a href="tel:8003009550">800-300-9550</a>

            </span>
          </p>
        </div>
      </Row> */}
    </nav>
  );
}

export default Header;
