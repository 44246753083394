/* eslint-disable   no-unused-vars */

import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header/header.component";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import Footer from "../components/footer/footer.component";
import "./Template.scss";
import "./Unbounce.scss";
import UnbounceHeader from "../components/header/unbounceheader.component";
import UnbounceFooter from "../components/footer/unbounce-footer.component";
const HowitWorks = () => {
  return (
    <Container fluid className=" themeLayout full-width-content">
      <Row className="no-gutter header">
        <Col sm={12}>
         <UnbounceHeader/>
        </Col>
      </Row>
      <div className="no-gutter site-inner">
        <div className="content-sidebar-wrap">
          <div className="content">
            <div className="  article entry ">
              <div className="lp-pob-box">
                <div className="color-overlay">
                  <div
                    className="lp-pom-text title_secondary"
                    style={{ paddingTop: "15px", height: "108px" }}
                  >
                    <h1
                      className=""
                      style={{ textAlign: "center", lineHeight: "58px" }}
                    >
                      <span style={{ fontSize: "36px" }}>
                        <span
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "700",
                            fontStyle: "normal",
                            color: "rgb(24, 2, 122)",
                          }}
                        >
                        Customized Debt Consolidation Loans

                        </span>
                      </span>
                    </h1>
                  </div>
                  <div className="lp-pom-text main-text" style={{ paddingTop: "15px" }}>
                    <p style={{ lineHeight: "20px", textAlign: "center" }}>
                      <span
                        style={{
                          fontWeight: "400",
                          fontFamily: "Roboto",
                          fontSize: "18px",
                          color: "rgb(39, 39, 39)",
                          fontStyle: "normal",
                        }}
                      >
           Here at National Debt Relief, we know everyone’s financial situation is different and that’s why we customize every debt consolidation loan program. We pride ourselves on offering a program that isn’t one size fits all. Instead, we take the time to analyze your specific financial needs and prepare a personalized debt consolidation loan quote that will lay out exactly how much you can expect to save.
                      </span>
                    </p>
                  </div>
                  <a
                    className=" lp-pom-button"
                    id="lp-pom-button-63"
                    href="/apply-loan/"
                    target="_self"
                    data-params="true"
                  >
                    <span className="label">Get Your Personalized Debt Consolidation Quote</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

   <UnbounceFooter/>
    </Container>
  );
};
export default HowitWorks;
