
/* eslint-disable   jsx-a11y/iframe-has-title */

import React from "react";

const ThankYouVideo= () => {

    return (
        <div className="thank-you-video">
        <h1>Here's What Some Clients Say:</h1>
        <div className="embed-container">
          <iframe
            src="https://www.youtube.com/embed/aegOlTIAvhY"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
          ></iframe>
        </div>
      </div>
     
    );
  };
  
  export default ThankYouVideo;
  