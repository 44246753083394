

/* eslint-disable   no-unused-vars */
/* eslint-disable    eqeqeq */
import React, { useEffect, useState } from "react";

import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./sticky-aside.style.scss";
import { faLock, faAngleDown, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import validator from "validator";
import MaskInput from "react-maskinput";
import applySubmit from "../../api/apply-api";

const StickyAside = (props) => {
  const history = useHistory();
  const selectDebitAmount = (debitAmount) => {
    return debitAmount;
  };
  const submitForm = async (body) => {
    const submitResponse = await applySubmit(body);

    if (submitResponse.status === 200) {
      const debtValue =
        debitAmount.indexOf("+") > 0
          ? debitAmount.slice(1)
          : debitAmount.split(" - ")[1].slice(1);

      const over10K = parseInt(debtValue) > 9;

      const badState = submitResponse.message.target;
      if (!over10K) {
        history.push("/thank-you-for-your-application", {
          firstName: firstName,
        });
      } else if (badState == true) {
        history.push("/thank-you-for-your-application", {
          firstName: firstName,
        });
      } else {
        history.push("/thank-you-for-applying", { firstName: firstName });
      }
    }
  };
  const handleApplySubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      var tcpainput = document.querySelector(
        'input[name$="xxTrustedFormCertUrl"]'
      );

      var tcpaurl = tcpainput ? tcpainput.value : "";
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email_address: email,
        debt_amount: debitAmount,
        url_of_page: window.location.href,
        web_referrer: document.referrer || "",
        cookie_enabled: navigator.cookieEnabled ? "true" : "false",
        tcpa: true,
        xxTrustedFormToken: tcpaurl,
        xxTrustedFormCertUrl: tcpaurl,
      });

      submitForm(body);
    }
  };

  const [debitAmount, setDebitAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isDebitAmountError, setDebitError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);

  const validate = () => {
    let errorFound = false;
    if (debitAmount === "") {
      setDebitError(true);
      errorFound = true;
    } else {
      setDebitError(false);
    }

    if (firstName === "") {
      setFirstNameError(true);
      errorFound = true;
    } else {
      setFirstNameError(false);
    }

    if (lastName === "") {
      setLastNameError(true);
      errorFound = true;
    } else {
      setLastNameError(false);
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      setPhoneError(true);
      errorFound = true;
    } else {
      setPhoneError(false);
    }

    if (!validator.isEmail(email)) {
      setEmailError(true);
      errorFound = true;
    } else {
      setEmailError(false);
    }

    return !errorFound;
  };
  const handleScrollForm = () => {
    const form = document.querySelector(".aside");
    const formPos = form.getBoundingClientRect();
    const videoPos = document
      .querySelector(".video-carousel")
      .getBoundingClientRect();
    const reviewPos = document
      .querySelector(".review-body")
      .getBoundingClientRect();
    const mainPos = document.querySelector(".hero").getBoundingClientRect();
    const mainfooterPos = document
      .querySelector(".main-footer")
      .getBoundingClientRect();

    if (mainPos.top < 0 || mainfooterPos.top > formPos.height) {
      form.classList.remove("aside__bottom");
      form.classList.add("aside__scrollable");
      form.style.top = 0 + "px";
    }
    if (mainfooterPos.top <= formPos.height) {
      form.classList.remove("aside__scrollable");
      form.classList.add("aside__bottom");
      form.style.top =
        mainPos.height +
        videoPos.height +
        reviewPos.height -
        formPos.height +
        "px";
    } else if (mainPos.top >= 0) {
      form.classList.remove("aside__scrollable");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollForm);
    return () => window.removeEventListener("scroll", handleScrollForm);
  });

  return (
    <div className="aside">
      <Form className="sticky-form">
        <h3>
          <em>Get started with a</em>
          <br />
          <strong>FREE </strong> Debt Relief Quote
        </h3>
        <Form.Group controlId="formGroupFirstName">
          <Form.Control
            type="text"
            placeholder="First Name"
            className={
              isFirstNameError ? "get-started-form-input-invalid" : null
            }
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupLastName">
          <Form.Control
            type="text"
            placeholder="Last Name"
            value={lastName}
            className={
              isLastNameError ? "get-started-form-input-invalid" : null
            }
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupPhone">
          <MaskInput
            className={isPhoneError ? "get-started-form-input-invalid" : null}
            size={20}
            maskChar="_"
            showMask
            mask={"(000)000-0000"}
            placeholder="Phone Number"
            onChange={(e) => setPhone(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Control
            className={isEmailError ? "get-started-form-input-invalid" : null}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Email Address"
          />
        </Form.Group>
        <Form.Group controlId="formGroupSelect">
          <Form.Control
            as="select"
            className={
              isDebitAmountError ? "get-started-form-input-invalid" : null
            }
            onChange={(e) => setDebitAmount(selectDebitAmount(e.target.value))}
            defaultValue="Enter Your Debt Amount"
          >
            <option>Enter Your Debt Amount</option>
            <option>$0 - $4,999</option>
            <option>$5,000 - $7,499</option>
            <option>$7,500 - $9,999</option>
            <option>$10,000 - $14,999</option>
            <option>$15,000 - $19,999</option>
            <option>$20,000 - $29,999</option>
            <option>$30,000 - $39,999</option>
            <option>$40,000 - $49,999</option>
            <option>$50,000 - $59,999</option>
            <option>$60,000 - $69,999</option>
            <option>$70,000 - $79,999</option>
            <option>$80,000 - $89,999</option>
            <option>$90,000 - $99,999</option>
            <option>$100,000+</option>
          </Form.Control>
          <FontAwesomeIcon size="xs" icon={faCaretDown}  />
        </Form.Group>
        <Button type="submit" onClick={(e) => handleApplySubmit(e)}>
          Get Free Quote!
        </Button>
        <p>
          <span className="form-disclaimer">
            By submitting, you agree that the phone number you are providing may
            be used to contact you by National Debt Relief (including autodialed
            and prerecorded calls or text/SMS messages). Msg. and data rates
            apply.
          </span>
        </p>
      </Form>

      <div className="bbb-block">
        <div>
          <p className="lock">
            <FontAwesomeIcon size="xs" icon={faLock} color="white" /> Your
            information is Secure
          </p>

          <a
            className="bbb-form"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899#bbbonlineclick"
          >
            <img
              alt="National Debt Relief, LLC BBB Business Review"
              src="https://seal-newyork.bbb.org/seals/blue-seal-250-52-national-debt-relief-llc-110899.png"
            />
          </a>
        </div>
      </div>
    </div>
  );
};
export default StickyAside;
