import React from "react";
import { Modal } from "react-bootstrap";

import ReactPlayer from "react-player";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTimes } from '@fortawesome/free-solid-svg-icons'

import "./video-modal.style.scss";

const VideoModal = (props) => {
  const { show, handleClose, video } = props

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="close" onClick={handleClose}><FontAwesomeIcon size="xs" icon={faTimes} color="white" /></div>
        <div className="modal-video-container">
          <ReactPlayer
            className="video-player"
            playing
            controls
            width="100%"
            height="100%"
            url={video?.src}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
