/* eslint-disable   no-unused-vars */
/* eslint-disable    jsx-a11y/anchor-has-content */
import React from "react";
import { Row, Col } from "react-bootstrap";
import { Images } from "../../assets/img/Images.js";
import "./footer.style.scss";
import "./get-out-of-debt-footer.style.scss";
const GetOutofDebtLoanFooter = (props, context) => {
  return (
    <Row className="no-gutter footer">
      <div className="content">
        <Col sm={6} md={6} lg={6} className="no gutter info">
          <div className="address">
            <div>National Debt Relief LLC</div>
            <div>180 Maiden Lane, 30th Floor</div>
            <div>New York, NY 10038</div>
          </div>
        </Col>
        <Col sm={6} md={6} lg={6} className="social">
          <ul>
            <li class="truste">
              <a href="http://privacy.truste.com/privacy-seal/National-Debt-Relief,-LLC/validation?rid=e0c88ae4-c80c-4c6c-9e69-ba3500ae3c5f"></a>
            </li>

            <li class="mcafee">
              <a href="https://www.mcafeesecure.com/verify?host=www.nationaldebtrelief.com"></a>
            </li>
          </ul>
        </Col>
      </div>
      <div className="secondary-footer">
        <Col sm={12} className="privacy">
          <div className="text">
            <div>
              <a
              
                rel="noreferrer noopener"
                href="/privacypolicy"
              >
                Privacy Policies
              </a>
              &nbsp;|&nbsp;
              <a
            
                rel="noreferrer noopener"
                href="/termsofsite/"
              >
                Terms and Conditions
              </a>
              &nbsp;|&nbsp;
              <div>
                2009- {new Date().getFullYear().toString()} National Debt
                Relief.{" "}
              </div>
            </div>
            <ul className="mobile-copyright">
              <li>
                <a href="/privacypolicy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/termsofsite">
                  Terms and Conditions
                </a>
              </li>
              <li>© 2009-2021, National Debt Relief.</li>
            </ul>
          </div>
        </Col>

        <Col sm={12} className="disclaimer">
          <div className="text">
            <p>
              All loans available through National Debt Relief LLC are made by
              lenders in their network and are subject to eligibility criteria
              and review of creditworthiness and history. All loan and rate
              terms are subject to eligibility restrictions, application review,
              credit score, loan amount, loan term, lender approval, and credit
              usage and history. Eligibility for a loan is not guaranteed. Loans
              are not available to residents of all states – please call a
              National Debt Relief representative for further details. Repayment{" "}
              <strong>
                <u>periods range from 24 to 60 months.</u>
              </strong>{" "}
              The range of APRs on loans{" "}
              <strong>is 6.25% to a maximum of 36.00%.</strong> APR. The APR
              calculation includes all applicable fees, including the loan
              origination fee. For Example, a four year $18,000 loan with an
              interest rate of 18.134% and corresponding APR of 21.08% would
              have an estimated monthly payment of $528.79 and a total cost
              payable of $8,281.48.{" "}
              <a href="https://www.nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/1250950">
                NMLS #1250950
              </a>
            </p>
            <p>
              IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
              To help the government fight the funding of terrorism and money
              laundering activities, Federal law requires all financial
              institutions to obtain, verify, and record information that
              identifies each person who opens an account. What this means for
              you: When you open an account, you will be asked for your name,
              address, date of birth, and other information that will allow the
              lender to identify you. You may also be asked to see your driver's
              license or other identifying documents.
            </p>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default GetOutofDebtLoanFooter;
