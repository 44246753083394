/* eslint-disable   no-unused-vars */
/* eslint-disable     eqeqeq */
/* eslint-disable    jsx-a11y/anchor-is-valid */



import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

import {getParams} from "../../utils/getParams";
import "./tyheader.style.scss";
import "./applyheader.style.scss";
const  ApplyHeader = (props) => {
    const history = useHistory();
    
    const params = getParams(window.location.href);
    const src = params.src;
  console.log('test params', params);
   return (
    <nav className="navigation navbar navbar-expand-lg ">
      <Row className="no-gutter navigation-wrapper" >
      <Col sm={6} md={6} lg={6} className="no gutter">
      <Navbar.Brand><a href="/"><img className="img-fluid" src={Logo} alt="" /></a></Navbar.Brand></Col>
      <Col sm={6} md={6} lg={6} className="applyLink-col widget-area header-widget-area" >
      <div className="widget widget_execphp phone-widget">
    <section className="widget widget_execphp phone-widget">
    <div className="widget-left">
{src == "toptenreview" ?
<a className="top-ten-reviews-blue" href="http://debt-consolidation-services-review.toptenreviews.com/national-debt-relief-review.html"> 
<img src="https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/top-ten-reviews-blue.png" alt="lending-tree-logo-stacked" width="350" height="64" className="alignnone size-full" />

</a>: <a className="apply-graphic">
          <img  src="https://www.nationaldebtrelief.com/wp-content/uploads/2019/12/tuC5zf3z.png" alt="2020 Google Reviews" width="222" height="98" className="alignnone size-full" />
      </a>

}

</div><div className="widget-right">
<p>Talk to a debt counselor toll free:</p>
<p className="phone">
        <span className="icon-wrap">
        <FontAwesomeIcon size="s" icon={faPhone} />
        </span>
   
            <span className="phone-text">
            <a href="tel:8003009550">800-300-9550</a>

            </span>
            </p>
</div>
     </section>
     </div>
     <div className="applyLinkmobile"> <img src="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png" alt="ndr-google-reviews" loading="lazy" data-src-mobile-1x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png" data-src-mobile-2x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_105e02e000000000000028.png" data-src-mobile-3x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_1000000000000000000028.png" title="ndr-google-reviews" srcSet="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_105e02e000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_1000000000000000000028.png 3x"/>

</div>


</Col></Row>


<Row className="no-gutter navigation-wrapper widget phone-widget" >
<div className="widget-right">

<p className="phone">
        <span className="icon-wrap">
        <FontAwesomeIcon size="xs" icon={faPhone} />
        </span>
   
            <span className="phone-text">
            <a href="tel:8003009550">800-300-9550</a>

            </span>
            </p>
</div>
    </Row>
    </nav>
  );
}

export default ApplyHeader;
