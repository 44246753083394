/* eslint-disable   no-unused-vars */
/* eslint-disable  jsx-a11y/anchor-has-content */
/* eslint-disable  react/jsx-no-target-blank*/
import React, { useCallback, useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUser } from "@fortawesome/free-solid-svg-icons";





const ClientReviews= () => {

  return (
    <div className="testimonials">
    <h4>Client Reviews</h4>

    <div className="right-box-core-trustpilot">
      <div className="right-box-middle">
        <div className="trustpilot-widget">
          <div className="tp-footer">
            <span className="tp-overview">
              <h3 className="excellent">Excellent</h3>
              <div className="five-star">
                <span className="screen-reader-text">
                  Rated Five Stars
                </span>

                <img
                  alt="Trustpilot star rating"
                  src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                  width="30px"
                />
                <img
                  alt="Trustpilot star rating"
                  src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                  width="30px"
                />
                <img
                  alt="Trustpilot star rating"
                  src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                  width="30px"
                />
                <img
                  alt="Trustpilot star rating"
                  src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                  width="30px"
                />
                <img
                  alt="Trustpilot star rating"
                  src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                  width="30px"
                />
              </div>
              <p className="tp-count">Based on REVIEWS</p>
            </span>
            <div className="clear"></div>
          </div>

          <div className="tp-body">
            <div className="smallquote">
              <FontAwesomeIcon size="xs" icon={faUser} />
              <div className="quote-content-wrap">
                <p className="quote-title">
                  "Best decision I&#39;ve ever made!"
                </p>
                <p className="quote-body">
                  They really care about &quot;you&quot; and making
                  your life easier. They answer all of your
                  questions and are very detailed.
                </p>
              </div>
              <div className="sq-meta">
                <div className="five-star">
                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />
                </div>
                <span className="pq-attr"> Kolbie P.</span>
              </div>
            </div>

            <div className="smallquote">
              <FontAwesomeIcon size="xs" icon={faUser} />
              <div className="quote-content-wrap">
                <p className="quote-title">
                  "Saved me from drowning in the debt."
                </p>
                <p className="quote-body">
                  I was up to my eyeballs in debt and National Debt
                  Relief saved me from drowning in the debt.
                </p>
              </div>
              <div className="sq-meta">
                <div className="five-star">
                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />
                </div>
                <span className="pq-attr">
                  {" "}
                  Joanna W.
                  <br />
                  Canal Winchester, OH
                </span>
              </div>
            </div>

            <div className="smallquote">
              <FontAwesomeIcon size="xs" icon={faUser} />
              <div className="quote-content-wrap">
                <p className="quote-title">
                  "Helped me settle my debts that were choking me."
                </p>
                <p className="quote-body">
                  National Debt Relief helped me settle my debts
                  that were choking me.
                </p>
              </div>
              <div className="sq-meta">
                <div className="five-star">
                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />
                </div>
                <span className="pq-attr">
                  {" "}
                  Bobbie A.
                  <br />
                  Charlotte, MI
                </span>
              </div>
            </div>

            <div className="smallquote">
              <FontAwesomeIcon size="xs" icon={faUser} />
              <div className="quote-content-wrap">
                <p className="quote-title">
                  "Taken a big weight off of my shoulders."
                </p>
                <p className="quote-body">
                  National Debt Relief has taken a big weight off of
                  my shoulders.
                </p>
              </div>
              <div className="sq-meta">
                <div className="five-star">
                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />

                  <img
                    alt="Trustpilot star rating"
                    src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                    width="30px"
                  />
                </div>
                <span className="pq-attr">
                  {" "}
                  Richard D.
                  <br />
                  Piffard, NY
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="trust-pilot-logo">
      <a
        target="_blank"
        href="https://www.trustpilot.com/review/www.nationaldebtrelief.com"
        title="this link will open in a new window"
      ></a>
    </div>
  </div>
  );

};

export default ClientReviews;
