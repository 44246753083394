/* eslint-disable  no-empty-pattern */
import React from "react";
import { useHistory } from "react-router-dom";

const ThankYouTopLoan = ({}) => {
  const history = useHistory();
  const firstName = history.location.state
    ? history.location.state.firstName
    : null;
  return (
    <div className="thank-you-top">
      <h1>Congratulations!</h1>
      <p>
        Dear<span> {firstName},</span>
      </p>
      <p>
        You've just taken the first step towards consolidating your debt and
        becoming debt free. One of our certified debt specialists will be
        contacting you shortly to complete your free quote and answer any
        questions you may have. For immediate assistance, please call us to
        speak to the specialist during our extended business hours. Once we
        connect, we can complete the pre-qualification process. To get a head
        start, please call the toll-free number below.
      </p>
      <p className="green-phone">
        <a href="tel:8003009550">800-300-9550</a>
      </p>
    </div>
  );
};

export default ThankYouTopLoan;
