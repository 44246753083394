
/* eslint-disable    react/jsx-no-duplicate-props */


import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import "./tyheader.style.scss";
import "./applyheader.style.scss";


const  FreeDebtHeader = ({ onNavOpen, onLinkClick, hasChildren }) => {
   return (
    <nav className="navigation navbar navbar-expand-lg">
      <Row className="no-gutter navigation-wrapper" >
      <Col sm={6} md={6} lg={6} className="no gutter">
      <Navbar.Brand><img className="img-fluid" src={Logo} alt="" /></Navbar.Brand></Col>
      <Col sm={6} md={6} sm={6} lg={6} className="applyLink-col" >
      <div className="widget widget_execphp phone-widget">
      <section className="widget widget_execphp phone-widget">
<div className="widget-right">
<p>Talk to a debt counselor toll free:</p>
<p className="phone">
        <span className="icon-wrap">
        <FontAwesomeIcon size="s" icon={faPhone} />
        </span>
   
            <span className="text">
            <a href="tel:8003009550">800-300-9550</a>

            </span>
            </p>
</div>
</section>
</div>


</Col>
<Col sm={6} md={6} sm={6} lg={6} className="applyLink-col freedebtmobileheader" >
<div className="applyLink ">  
<p className="phone">
<span className="text">
      <a  href="tel:8003009550" className="header-phone  " ><span>
     
</span>800-300-9550</a></span></p></div>


</Col>


</Row>
    </nav>
  );
}

export default FreeDebtHeader;
