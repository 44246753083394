
/* eslint-disable     react/jsx-no-target-blank*/
import React from "react";


const WhyChooseUs= () => {

  return (
    <div className="why-choose-us">
    <h4>Why Choose Us</h4>
    <p>
      National Debt Relief is one of the country's largest and one
      of the most reputable debt settlement companies. We are made
      up of energetic, smart &amp; compassionate individuals who are
      passionate about helping thousands of Americans with debt
      relief. Most importantly we're all about helping our customers
      through a tough financial time in their lives with education
      and individual customer service.
    </p>
    <p className="sidebar-logos">
      <a
        className="bbb-logo external"
        href="https://www.bbb.org/new-york-city/business-reviews/credit-and-debt-counseling/national-debt-relief-llc-in-new-york-ny-110899/"
        rel="nofollow"
        target="_blank"
      >
        Better Business Bureau Accredited
      </a>
      <a
        className="top-ten-excellence-logo external"
        href="http://www.toptenreviews.com/money/debt/best-debt-settlement-companies/national-debt-relief-review/"
        rel="nofollow"
        target="_blank"
      >
        Top Ten Reviews - Excellence
      </a>
    </p>
  </div>
   
  );
};

export default WhyChooseUs;
