/* eslint-disable   no-unused-vars */
/* eslint-disable  eqeqeq */
import React, { useCallback, useEffect, useState } from "react";

import { Container, Row, Form, Button, Col } from "react-bootstrap";

import Footer from "../components/footer/footer.component";


import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import MaskInput from "react-maskinput";
import { useHistory } from "react-router-dom";
import validator from "validator";
import applySubmit from "../api/apply-api";
import "./Apply.scss";
import "./_get-out-of-debt.scss";
import CAReviewsScroll from "../components/careviewslist/CAReviewsScroll";
import GetOutofDebtLoanFooter from "../components/footer/get-out-of-debt-footer";
import GetOutofDebtHeader from "../components/header/getoutofdebtheader.component";

const GetOutofDebtLoan = () => {
  const [debitAmount, setDebitAmount] = useState("");

  const [step, setStep] = useState("1");
  const history = useHistory();

  const submitForm = async (body) => {
    setLoading(true);
    const submitResponse = await applySubmit(body);

    if (submitResponse.status === 200) {
      const debtValue =
        debitAmount.indexOf("+") > 0
          ? debitAmount.slice(1)
          : debitAmount.split(" - ")[1].slice(1);

      const over10K = parseInt(debtValue) > 9;

      const badState = submitResponse.message.target;
      if (!over10K) {
        history.push("/thank-you-for-your-loan-application", {
          firstName: firstName,
        });
      } else if (badState == true) {
        history.push("/thank-you-for-your-loan-application", {
          firstName: firstName,
        });
      } else {
        history.push("/thank-you-loan-application", { firstName: firstName });
      }
      setLoading(false);
    }
  };
  const onApplyNext = (event) => {
    event.preventDefault();

    let debtAmount = event.target.debtAmount.value;

    console.log("test event value", event.target.debtAmount.value);
    if (debtAmount != " ") {
      setStep("2");
      setDebitAmount(debtAmount);
    } else if (debtAmount == " ") {
      event.target.debtAmount.classList.add("invalid");
    }
  };
  const handleApplySubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      var tcpainput = document.querySelector(
        'input[name$="xxTrustedFormCertUrl"]'
      );

      var tcpaurl = tcpainput ? tcpainput.value : "";
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email_address: email,
        debt_amount: debitAmount,
        url_of_page: window.location.href,
        web_referrer: document.referrer || "",
        cookie_enabled: navigator.cookieEnabled ? "true" : "false",
        tcpa: true,
        xxTrustedFormToken: tcpaurl,
        xxTrustedFormCertUrl: tcpaurl,
      });

      submitForm(body);
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);

  const validate = () => {
    let errorFound = false;

    if (firstName === "") {
      setFirstNameError(true);
      errorFound = true;
    } else {
      setFirstNameError(false);
    }

    if (lastName === "") {
      setLastNameError(true);
      errorFound = true;
    } else {
      setLastNameError(false);
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      setPhoneError(true);
      errorFound = true;
    } else {
      setPhoneError(false);
    }

    if (!validator.isEmail(email)) {
      setEmailError(true);
      errorFound = true;
    } else {
      setEmailError(false);
    }

    return !errorFound;
  };

  return (
    <Container fluid className="themeLayout get-out-of-debt-container">
      <Row className="no-gutter header">
        <Col lg={12}>
        <GetOutofDebtHeader/>
        </Col>
      </Row>
      <div className="no-gutter site-inner">
        <div className="content-sidebar-wrap">
          <div className="content">
            <div className="section section-one">
              <div className={`form-page-1 ${step == "1" ? "show" : "hide"}`}>
              <form id="applyStep1" onSubmit={onApplyNext}>
                <div className="form-heading">
                  <h1>Contact Us Right Now To Consolidate Your Debt</h1>
                </div>

                <div className="select-wrapper">
                  <label for="id_debt_amount"></label>
                  <select
                    name="debtAmount"
                    className="get-started-form-input apply-form-select"
                    id="id_debt_amount"
                  >
                    <option value=" " defaultValue="1">
                      Select Your Debt Amount
                    </option>

                    <option value="$0 - $4,999">$0 - $4,999</option>

                    <option value="$5,000 - $7,499">$5,000 - $7,499</option>

                    <option value="$7,500 - $9,999">$7,500 - $9,999</option>

                    <option value="$10,000 - $14,999">$10,000 - $14,999</option>

                    <option value="$15,000 - $19,999">$15,000 - $19,999</option>

                    <option value="$20,000 - $29,999">$20,000 - $29,999</option>

                    <option value="$30,000 - $39,999">$30,000 - $39,999</option>

                    <option value="$40,000 - $49,999">$40,000 - $49,999</option>

                    <option value="$50,000 - $59,999">$50,000 - $59,999</option>

                    <option value="$60,000 - $69,99">$60,000 - $69,999</option>

                    <option value="$70,000 - $79,999">$70,000 - $79,999</option>

                    <option value="$80,000 - $89,999">$80,000 - $89,999</option>

                    <option value="$90,000 - $99,999">$90,000 - $99,999</option>

                    <option value="$100,000+">$100,000+</option>
                  </select>
                </div>

                <button
                  type="submit"
                  className="form-next-button"
                
                >
                  Next Step
                </button>
                </form>
              </div>

              <div className={`form-page-2 ${step == "2" ? "show" : "hide"}`}>
                <div className="wrap">
                  <ul>
                    <li className="left-group">
                      <fieldset>
                        <legend></legend>
                        <ul>
                          <li>
                            <h2>Get A Quick Consolidation <br/>Estimate FREE
</h2>
                          </li>
                          <li>
                            <div className="quick-estimate">
                              <ul>
                                <li className="reduce-your-debts">
                                  <span className="list-title">
                                  Consolidate Your Debts

                                  </span>
                                  <p>
                                    Get a lower monthly payment and{" "}
                                    <b>CONSOLIDATE YOUR DEBT</b> to a{" "}
                                    <b>FRACTION OF WHAT YOU OWE</b> without
                                    bankruptcy.
                                  </p>
                                </li>

                                <li className="compare-options">
                                  <span className="list-title">
                                    Compare Your Debt Relief Options{" "}
                                  </span>
                                  <p>
                                    Find out which debt relief programs you
                                    qualify for. There are options{" "}
                                    <b>EVEN IF YOU HAVE BAD OR POOR CREDIT.</b>
                                  </p>
                                </li>

                                <li className="no-fees">
                                  <span className="list-title">
                                  No Fees To Start And No Obligation
                                  </span>
                                  <p>
                                  Get out of debt in just{" "}
                                    <b>
                                    24 TO 60 MONTHS!
                                    </b>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </fieldset>
                    </li>
                    <li className="right-group">
                      <fieldset>
                        <h3>See If You Qualify</h3>
                        <p className="form-tagline">
                          See How Fast You Can Be Out Of Debt
                        </p>
                        <div className="red-arrow"></div>

                        <Form.Group controlId="formGroupFirstName">
                          <Form.Control
                            type="text"
                            placeholder="First Name"
                            id="id_first_name"
                            className={`get-started-form-input ${
                              isFirstNameError ? "invalid" : null
                            }`}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="formGroupLastName">
                          <Form.Control
                            type="text"
                            id="id_last_name"
                            placeholder="Last Name"
                            value={lastName}
                            className={`get-started-form-input ${
                              isLastNameError ? "invalid" : null
                            }`}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="formGroupPhone">
                          <MaskInput
                            className={`get-started-form-input ${
                              isPhoneError ? "invalid" : null
                            }`}
                            size={20}
                            maskChar="_"
                            showMask
                            mask={"(000)000-0000"}
                            placeholder="999 999 9999"
                            onChange={(e) => setPhone(e.target.value)}
                            id="id_phone_number"
                          />
                        </Form.Group>

                        <Form.Group controlId="formGroupEmail">
                          <Form.Control
                            className={`get-started-form-input ${
                              isEmailError ? "invalid" : null
                            }`}
                            onChange={(e) => setEmail(e.target.value)}
                            type="text"
                            placeholder="Email Address"
                            id="id_email_address"
                          />
                        </Form.Group>

                        <Button
                          type="submit"
                          onClick={(e) => handleApplySubmit(e)}
                        >
                          Consolidate My Debt!
                        </Button>
                      </fieldset>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="section section-two">
              <div className="wrap">
                <h2 className="fancy">
                  <span>Partner With The Leader</span>
                </h2>
                <h3>
                  Free Debt Consolidation Estimate - See How Much You Can Save
                  Each Month
                </h3>
                <ul>
                  <li>
                    <FontAwesomeIcon
                      className="fa fa-check-circle"
                      size="xs"
                      icon={faCheckCircle}
                    />{" "}
                    <b>Get A Free</b> Debt Consolidation Estimate
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="fa fa-check-circle"
                      size="xs"
                      icon={faCheckCircle}
                    />{" "}
                    <b>Get Out of Debt</b> Without Bankruptcy
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="fa fa-check-circle"
                      size="xs"
                      icon={faCheckCircle}
                    />{" "}
                    <b>See How Quickly</b> You Can Be Debt Free
                  </li>
                  <li>
                    <FontAwesomeIcon
                      className="fa fa-check-circle"
                      size="xs"
                      icon={faCheckCircle}
                    />{" "}
                    <b>No Upfront Fees</b> And No Obligation
                  </li>
                </ul>
              </div>
            </div>
            <div className="section section-three">
              <CAReviewsScroll />
            </div>
          </div>
        </div>
      </div>

 

     <GetOutofDebtLoanFooter/>
    </Container>
  );
};

export default GetOutofDebtLoan;
