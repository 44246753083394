/* eslint-disable   no-unused-vars */
/* eslint-disable   eqeqeq */
import React, { useEffect, useState } from "react";

import validator from "validator";
import applySubmit from "../../api/apply-api";

import chevronIcon from "../../assets/img/chevron-right-solid-01.png";
import { Form, Button, Row } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight} from "@fortawesome/free-solid-svg-icons";

import MaskInput from "react-maskinput";
import { useHistory } from "react-router-dom";

const ApplyFormStep2 = (props)=>{
const {debitAmount, step} = props;
   
    const history = useHistory();

    const submitForm = async (body) => {
        setLoading(true);
        const submitResponse = await applySubmit(body);
    
        if (submitResponse.status === 200) {
          const debtValue =
            debitAmount.indexOf("+") > 0
              ? debitAmount.slice(1)
              : debitAmount.split(" - ")[1].slice(1);
    
          const over10K = parseInt(debtValue) > 9;
    
          const badState = submitResponse.message.target;
          if (!over10K) {
            history.push("/thank-you-for-your-loan-application", {
              firstName: firstName,
            });
          } else if (badState == true) {
            history.push("/thank-you-for-your-loan-application", {
              firstName: firstName,
            });
          } else {
            history.push("/thank-you-loan-application", { firstName: firstName });
          }
          setLoading(false)
        }
        
      };

      const handleApplySubmit = (e) => {
        e.preventDefault();
    
        if (validate()) {
          var tcpainput = document.querySelector(
            'input[name$="xxTrustedFormCertUrl"]'
          );
    
          var tcpaurl = tcpainput ? tcpainput.value : "";
          var body = JSON.stringify({
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
            email_address: email,
            debt_amount: debitAmount,
            url_of_page: window.location.href,
            web_referrer: document.referrer || "",
            cookie_enabled: navigator.cookieEnabled ? "true" : "false",
            tcpa: true,
            xxTrustedFormToken: tcpaurl,
            xxTrustedFormCertUrl: tcpaurl,
          });
    
          submitForm(body);
        }
      };
      const [firstName, setFirstName] = useState("");
      const [lastName, setLastName] = useState("");
      const [phone, setPhone] = useState("");
      const [email, setEmail] = useState("");
      const [isLoading, setLoading] = useState(false);

      const [isFirstNameError, setFirstNameError] = useState(false);
      const [isLastNameError, setLastNameError] = useState(false);
      const [isPhoneError, setPhoneError] = useState(false);
      const [isEmailError, setEmailError] = useState(false);

      const validate = () => {
        let errorFound = false;
      
        if (firstName === "") {
          setFirstNameError(true);
          errorFound = true;
        } else {
          setFirstNameError(false);
        }
    
        if (lastName === "") {
          setLastNameError(true);
          errorFound = true;
        } else {
          setLastNameError(false);
        }
    
        if (!validator.isMobilePhone(phone, "en-US")) {
          setPhoneError(true);
          errorFound = true;
        } else {
          setPhoneError(false);
        }
    
        if (!validator.isEmail(email)) {
          setEmailError(true);
          errorFound = true;
        } else {
          setEmailError(false);
        }
    
        return !errorFound;
      };

        return (
<div className={`page-two ${step =='2' ?'show':'hide'}`}>
            <div className={"apply_form_step_2_wrapper"  }>
                  {/*isLoading == true && <div className="loader"></div>*/}

                <div className="apply_form_title_wrapper">
                    <h1 className="form_title_primary"><strong>Consolidate Your Debt and Stress </strong></h1><h2 className="form_title_secondary">In Less Than 30 Seconds</h2>
                </div>

                <div className="form_description_wrapper">
                    <p className="description">Complete the form below to get started today:</p>
                    <div className="progress-bar-graphic"></div>
                </div>
                
                <Form id="applyStep2" onSubmit={handleApplySubmit}>
                <div className="apply_form_fields_wrapper">
                <Form.Group controlId="formGroupFirstName">
          <Form.Control
            type="text"
            placeholder="First Name" id="id_first_name"
            className={`get-started-form-input ${isFirstNameError? "invalid" : null}`}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupLastName">
          <Form.Control
            type="text" id="id_last_name"
            placeholder="Last Name"
            value={lastName}
            className={`get-started-form-input ${isLastNameError ? "invalid" : null}`}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupPhone">
          <MaskInput
            className={`get-started-form-input ${isPhoneError ? "invalid" : null}`}
            size={20}
            maskChar="_"
            showMask
            mask={"(000)000-0000"}
            placeholder="999 999 9999"
            onChange={(e) => setPhone(e.target.value)} id="id_phone_number"
          />
        </Form.Group>
         
    
            

                        <Form.Group controlId="formGroupEmail">
          <Form.Control
            className={`get-started-form-input ${isEmailError ? "invalid" : null}`}
            onChange={(e) => setEmail(e.target.value)}
            type="text"
            placeholder="Email Address"
            id="id_email_address"
          />
        </Form.Group>
                    </div>
                    <div className="apply_form_footer"><button type="submit" className="big-red-button --second"><span className="top-text">Click Here</span><span className="bottom-text">To See If You Qualify For Debt Consolidation</span>
                    <FontAwesomeIcon size="xs" icon={faChevronRight} color="white" /> 
                    </button>
                       
                    </div>

                    </Form>

            </div>
            </div>
        );
 
}


export default ApplyFormStep2; 