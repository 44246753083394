/* eslint-disable   no-unused-vars */
/* eslint-disable     react/jsx-no-duplicate-props */
import React from "react";
import { Navbar, Row, Col } from "react-bootstrap";
import Logo from "../../assets/img/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import "./header.style.scss";
import "./unbounceheader.style.scss";

const UnbounceHeader = ({ onNavOpen, onLinkClick, hasChildren }) => {
  return (
    <nav className="navigation navbar navbar-expand-lg unbounceheader">
      <Row className="no-gutter navigation-wrapper">
        <Col sm={8} md={8} lg={8} className="no gutter">
          <Navbar.Brand>
          <a href="/">  <img className="img-fluid" src={Logo} alt="" /></a>
          </Navbar.Brand>
        </Col>
        <Col sm={4} md={4} sm={4} lg={4} className="applyLink-col">
          <div className="applyLink">
            {" "}
            <p style={{ lineHeight: "24px", textAlign: "center" }}>
              Talk to a debt counselor toll free:
            </p>{" "}
            <a href="tel:800-300-9550" className="header-phone">
              <span></span>800-300-9550
            </a>
          </div>

          <div className="applyLinkmobile">
            {" "}
            <img
              src="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png"
              alt="ndr-google-reviews"
              loading="lazy"
              data-src-mobile-1x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png"
              data-src-mobile-2x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_105e02e000000000000028.png"
              data-src-mobile-3x="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_1000000000000000000028.png"
              title="ndr-google-reviews"
              srcset="//d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_102p017000000000000028.png 1x, //d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_105e02e000000000000028.png 2x, //d9hhrg4mnvzow.cloudfront.net/www.nationaldebtrelief.com/about-us/zvxwth-tuc5zf3z_1000000000000000000028.png 3x"
            />
          </div>
        </Col>
      </Row>
      <Row className="applyPhonemobile no-gutter navigation-wrapper">
        <a className="header-phone" href="tel:800-300-9550">
          <span>800-300-9550</span>
        </a>
      </Row>
    </nav>
  );
};

export default UnbounceHeader;
