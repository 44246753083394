

/* eslint-disable   jsx-a11y/anchor-has-content */
/* eslint-disable    jsx-a11y/iframe-has-title */
/* eslint-disable   eqeqeq */
/* eslint-disable  no-unused-vars */
/* eslint-disable   react-hooks/exhaustive-deps */
/* eslint-disable   jsx-a11y/alt-text */

import React, { useCallback, useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";

import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";

import Header from "../components/header/header.component";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import Footer from "../components/footer/footer.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faUser,
  faLock,
  faCheckCircle,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { array_rand } from "../utils/shuffleArrayUtil";
import validator from "validator";
import MaskInput from "react-maskinput";
import applySubmit from "../api/apply-api";
import FreeDebtHeader from "../components/header/freedebtheader";

import "./Template.scss";
import "./_sitemap.scss";
import "./_simple-content-page.scss";
import "./FreeDebtConsolidationLoanQuote.scss";
import "./_get-started-form.scss";
import "./_after-entry-reviews.scss";
import "./_consumer-affairs-widget.scss";
import FreeDebtTrustLogosRow from "../components/footer/FreeDebtTrustLogosRow";
import FreeDebtQuoteFooter from "../components/footer/freedebtquotefooter.component";
const FreeDebtConsolidationLoanSavings = () => {
  const [data, setData] = useState({ isReady: false, review: "" });
  const history = useHistory();

  const selectDebitAmount = (debitAmount) => {
    return debitAmount;
  };
  const submitForm = async (body) => {
    const submitResponse = await applySubmit(body);

    if (submitResponse.status === 200) {
      const debtValue =
        debitAmount.indexOf("+") > 0
          ? debitAmount.slice(1)
          : debitAmount.split(" - ")[1].slice(1);

      const over10K = parseInt(debtValue) > 9;

      const badState = submitResponse.message.target;
      if (!over10K) {
        history.push("/thank-you-for-your-loan-application", {
          firstName: firstName,
        });
      } else if (badState == true) {
        history.push("/thank-you-for-your-loan-application", {
          firstName: firstName,
        });
      } else {
        history.push("/thank-you-loan-application", { firstName: firstName });
      }
    }
  };
  const handleApplySubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      var tcpainput = document.querySelector(
        'input[name$="xxTrustedFormCertUrl"]'
      );

      var tcpaurl = tcpainput ? tcpainput.value : "";
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email_address: email,
        debt_amount: debitAmount,
        url_of_page: window.location.href,
        web_referrer: document.referrer || "",
        cookie_enabled: navigator.cookieEnabled ? "true" : "false",
        tcpa: true,
        xxTrustedFormToken: tcpaurl,
        xxTrustedFormCertUrl: tcpaurl,
      });

      submitForm(body);
    }
  };

  const [debitAmount, setDebitAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isDebitAmountError, setDebitError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);

  const validate = () => {
    let errorFound = false;
    if (debitAmount === "") {
      setDebitError(true);
      errorFound = true;
    } else {
      setDebitError(false);
    }

    if (firstName === "") {
      setFirstNameError(true);
      errorFound = true;
    } else {
      setFirstNameError(false);
    }

    if (lastName === "") {
      setLastNameError(true);
      errorFound = true;
    } else {
      setLastNameError(false);
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      setPhoneError(true);
      errorFound = true;
    } else {
      setPhoneError(false);
    }

    if (!validator.isEmail(email)) {
      setEmailError(true);
      errorFound = true;
    } else {
      setEmailError(false);
    }

    return !errorFound;
  };
  useEffect(() => {
    getReviews(setData);
  }, []);

  return (
    <Container
      fluid
      className=" themeLayout template freedebtconsolidationquote"
    >
      <div class="fixed-mobile-footer">
        <a class="button" href="tel:800-300-9550">
          Click To Call Now
        </a>
      </div>
      <Row className="no-gutterheader site-header applyheader">
        <Col sm={12}>
          <FreeDebtHeader />
        </Col>
      </Row>
      <div className="site-inner free-debt-consolidation-quote">
        <div className="content-sidebar-wrap">
          <main className="content sitemap-content-half">
            <article
              itemscope='"itemscope"'
              itemtype='"https://schema.org/CreativeWork"'
            >
              <div className="entry-content">
                <h4>
                  Attention! Watch This If You Want To Consolidate Your Credit
                  Card Debt
                </h4>
                <h2>
                  Consolidate Your Debt With A Low Rate Debt Consolidation Loan
                </h2>
                <div className="embed-container">
                  <iframe
                    src="https://www.youtube.com/embed/X33x6SxRtVk"
                    allowfullscreen=""
                    frameborder="0"
                  ></iframe>
                </div>
                <dic className="freedebtloanquotemobileform">
                  <Form className="free-consolidation-quote-form">
                    <h3 className="widget-title">
                      Free Debt Consolidation Quote
                    </h3>
                    <Form.Group controlId="formGroupFirstName">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        className={
                          `get-started-form-input ` +
                          (isFirstNameError
                            ? "get-started-form-input-invalid"
                            : "")
                        }
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupLastName">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        className={
                          `get-started-form-input ` +
                          (isLastNameError
                            ? "get-started-form-input-invalid"
                            : "")
                        }
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupPhone">
                      <MaskInput
                        className={
                          `get-started-form-input ` +
                          (isPhoneError ? "get-started-form-input-invalid" : "")
                        }
                        size={20}
                        maskChar="_"
                        showMask
                        mask={"(000)000-0000"}
                        placeholder="Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupEmail">
                      <Form.Control
                        className={
                          `get-started-form-input ` +
                          (isEmailError ? "get-started-form-input-invalid" : "")
                        }
                        onChange={(e) => setEmail(e.target.value)}
                        type="text"
                        placeholder="Email Address"
                      />
                    </Form.Group>
                    <Form.Group controlId="formGroupSelect">
                      <Form.Control
                        as="select"
                        className={
                          `get-started-form-input ` +
                          (isDebitAmountError
                            ? "get-started-form-input-invalid"
                            : "")
                        }
                        onChange={(e) =>
                          setDebitAmount(selectDebitAmount(e.target.value))
                        }
                        defaultValue="Enter Your Debt Amount"
                      >
                        <option>Enter Your Debt Amount</option>
                        <option>$0 - $4,999</option>
                        <option>$5,000 - $7,499</option>
                        <option>$7,500 - $9,999</option>
                        <option>$10,000 - $14,999</option>
                        <option>$15,000 - $19,999</option>
                        <option>$20,000 - $29,999</option>
                        <option>$30,000 - $39,999</option>
                        <option>$40,000 - $49,999</option>
                        <option>$50,000 - $59,999</option>
                        <option>$60,000 - $69,999</option>
                        <option>$70,000 - $79,999</option>
                        <option>$80,000 - $89,999</option>
                        <option>$90,000 - $99,999</option>
                        <option>$100,000+</option>
                      </Form.Control>
                    </Form.Group>
                    <Button
                      type="submit"
                      className="get-started-form-submit"
                      onClick={(e) => handleApplySubmit(e)}
                    >
                      Get Free Quote!
                    </Button>
                    <p>
                      {/*<span className="form-disclaimer">
            By submitting, you agree that the phone number you are providing may
            be used to contact you by National Debt Relief (including autodialed
            and prerecorded calls or text/SMS messages). Msg. and data rates
            apply.
            </span>*/}
                    </p>
                  </Form>
                </dic>

                <div className="video-phone">
                  <h4>
                    Call Now!
                    <br />
                    <a href="tel:800-300-9550">800-300-9550</a>
                  </h4>
                </div>

                <ul className="cut-up-credit-card-debt">
                  <li>
                    <p>
                      Consolidate Your Credit Card Debt And Save Each Month!
                    </p>
                  </li>
                  <li>
                    <p>Get One Low Monthly Payment You Can Afford!</p>
                  </li>
                  <li>
                    <p>
                      Compare Your Debt Consolidation Options Even If You Have
                      Bad Credit!
                    </p>
                  </li>
                </ul>

                <h3>Read Our Testimonials Below!</h3>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-one"></div>
                  <p className="quote">
                    "I strongly appreciated the professionalism and the help
                    made available to us by a personal phone call as opposed to
                    an automated calling system. Mr. Cole's knowledge of the
                    process was reassuring. I am very satisfied with the help
                    and assistance I received and will recommend National Debt
                    Relief to anyone I know that may be considering a similar
                    financial decision. "
                  </p>
                  <p className="cite">N.H. from Girard, PA</p>
                </div>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-two"></div>
                  <p className="quote">
                    "I gave a lot of thought and research as to which company to
                    go with for my debt consolidation because it was
                    embarrassing for me. When I submitted my application to
                    National Debt Relief, I received a call almost immediately
                    from ***. *** was polite and professional but most
                    importantly, he did not make me feel as if I was a idiot for
                    getting myself in debt over my head. I have already
                    suggested National Debt Relief to 2 of my friends and will
                    continue to do so. Thank you National Debt for helping me
                    and getting me to see the light at the end of the my debt
                    tunnel!"
                  </p>
                  <p className="cite">C.D. from Newton, NJ</p>
                </div>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-three"></div>
                  <p className="quote">
                    "It was nice to deal with a friendly customer service rep. I
                    liked the fact that we could communicate through email.
                    Everything was explained to me, and I like the flexibility
                    that is giving to the customers about there plain. I did
                    give it a 5 star rating because the people you talk to you
                    can understand. They are not pushy at all. The program is
                    quit flexible as far as payment date, which cards you want
                    to pay off. Just like everything about this company. Hope
                    everything I said holds True!!"
                  </p>
                  <p className="cite">B.L. from Lake Clear, NY</p>
                </div>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-four"></div>
                  <p className="quote">
                    "I am just starting with National Debt Relief, however the
                    staff has been very helpful and have answered all my
                    questions. I have been very satisfied with the customer
                    support so far and would recommend others to check it out."
                  </p>
                  <p className="cite">M.M. from Glenarm, IL</p>
                </div>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-five"></div>
                  <p className="quote">
                    "They made the enrollment process both easy and hassle free.
                    Explained your options and how the program works thoroughly
                    and made me feel as though they had my back every step of
                    the way."
                  </p>
                  <p className="cite">D.C. from Bronx, NY</p>
                </div>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-six"></div>
                  <p className="quote">
                    "Thank you, I spoke to a very knowledgeable staff member who
                    guided me through the process and also gave me several ideas
                    for more effective cost conscious living. I really felt that
                    she cared and was doing this because of a genuine interest
                    to help me. With such a stressful mind-boggling tasks I am
                    facing, she made me feel as though there is a light at the
                    end of my tunnel. Very compassionate, understanding and
                    professional."
                  </p>
                  <p className="cite">M.S. from Morris, IL</p>
                </div>
                <div className="testimonial">
                  <div className="testimonial-image testimonial-image-seven"></div>
                  <p className="quote">
                    "*** that helped me on the phone, explained all of the
                    options available, took time to answer my questions and made
                    sure I understood everything. I understand that this will
                    negatively impact my credit rating but in the long run, I
                    will be so much better off. Very professional organization,
                    and I would recommend this company to anyone that may be
                    having issues. This was my decision to move forward with the
                    best option available to me, and I am very thankful that
                    there is an honest company that is rated by the better
                    business bureau, which is why I did reach out to the
                    company. Very professional, very helpful, and very
                    understanding, and willing to help!"
                  </p>
                  <p className="cite">M.H. from Chicago IL</p>
                </div>
              </div>
            </article>
          </main>
          <aside className="sitemap-aside simple-content-sidebar free-debt-consolidation-quote-aside">
            <div className="container resolve-debit widget_text">
              <div
                className="widget_text widget-wrap"
                style={{ marginBottom: "20px" }}
              >
                <h3 className="widgettitle widget-title">
                  Consolidate Your Debt!
                </h3>
                <div className="textwidget custom-html-widget">
                  <strong>Find out how NDR could help.</strong>

                  <ul>
                    <li>
                      <FontAwesomeIcon
                        size="xs"
                        className="fa fa-check-circle-o"
                        icon={faCheckCircle}
                      />
                      Get one low monthly payment
                    </li>
                    <li>
                      <FontAwesomeIcon
                        size="xs"
                        className="fa fa-check-circle-o"
                        icon={faCheckCircle}
                      />
                      Avoid bankruptcy
                    </li>
                    <li>
                      <FontAwesomeIcon
                        size="xs"
                        className="fa fa-check-circle-o"
                        icon={faCheckCircle}
                      />
                      Get out of debt in 24-48 months
                    </li>
                  </ul>

                  <a href="/apply-loan/" className="button">
                    Get Your Free evaluation
                  </a>
                </div>
              </div>
              <div className="widget"></div>
            </div>
            <div className="widget-wrap" style={{ marginBottom: "20px" }}>
              <div className="textwidget">
                <div className="big-red-down-arrow"></div>
              </div>
            </div>
            <div className="widget">
              <Form className="free-consolidation-quote-form">
                <h3 className="widget-title">Free Debt Consolidation Quote</h3>
                <Form.Group controlId="formGroupFirstName">
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    className={
                      `get-started-form-input ` +
                      (isFirstNameError ? "get-started-form-input-invalid" : "")
                    }
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupLastName">
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    className={
                      `get-started-form-input ` +
                      (isLastNameError ? "get-started-form-input-invalid" : "")
                    }
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupPhone">
                  <MaskInput
                    className={
                      `get-started-form-input ` +
                      (isPhoneError ? "get-started-form-input-invalid" : "")
                    }
                    size={20}
                    maskChar="_"
                    showMask
                    mask={"(000)000-0000"}
                    placeholder="Phone Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId="formGroupEmail">
                  <Form.Control
                    className={
                      `get-started-form-input ` +
                      (isEmailError ? "get-started-form-input-invalid" : "")
                    }
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email Address"
                  />
                </Form.Group>
                <Form.Group controlId="formGroupSelect">
                  <Form.Control
                    as="select"
                    className={
                      `get-started-form-input ` +
                      (isDebitAmountError
                        ? "get-started-form-input-invalid"
                        : "")
                    }
                    onChange={(e) =>
                      setDebitAmount(selectDebitAmount(e.target.value))
                    }
                    defaultValue="Enter Your Debt Amount"
                  >
                    <option>Enter Your Debt Amount</option>
                    <option>$0 - $4,999</option>
                    <option>$5,000 - $7,499</option>
                    <option>$7,500 - $9,999</option>
                    <option>$10,000 - $14,999</option>
                    <option>$15,000 - $19,999</option>
                    <option>$20,000 - $29,999</option>
                    <option>$30,000 - $39,999</option>
                    <option>$40,000 - $49,999</option>
                    <option>$50,000 - $59,999</option>
                    <option>$60,000 - $69,999</option>
                    <option>$70,000 - $79,999</option>
                    <option>$80,000 - $89,999</option>
                    <option>$90,000 - $99,999</option>
                    <option>$100,000+</option>
                  </Form.Control>
                </Form.Group>
                <Button
                  type="submit"
                  className="get-started-form-submit"
                  onClick={(e) => handleApplySubmit(e)}
                >
                  Get Free Quote!
                </Button>
                <p>
                  {/*<span className="form-disclaimer">
            By submitting, you agree that the phone number you are providing may
            be used to contact you by National Debt Relief (including autodialed
            and prerecorded calls or text/SMS messages). Msg. and data rates
            apply.
            </span>*/}
                </p>
              </Form>
            </div>
            <div className="widget">
              <div className="ca-widget">
                <span className="ca-heading">
                  <FontAwesomeIcon size="xs" icon={faLock} color="white" />
                  &nbsp;Trusted By Our Clients
                </span>

                <div className="ca-body">
                  <div itemprop="itemReviewed">
                    <meta
                      itemprop="image"
                      content="https://ww3.nationaldebtrelief.com/static/ndr_app/images/logo-280.png"
                      alt="National Debt Relief"
                    />
                    <meta content="National Debt Relief" />
                  </div>

                  <div className="smallquote">
                    <FontAwesomeIcon
                      size="xs"
                      icon={faUser}
                      className="fa fa-user"
                    />
                    <div className="quote-content-wrap">
                      <p className="quote-title">
                        {data.review ? data.review.subject : ""}
                      </p>
                      <p className="quote-body" itemprop="description">
                        {data.review !== "" ? data.review.moderated_text : ""}
                      </p>
                    </div>
                    <div className="sq-meta">
                      <div className="five-star">
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="30px"
                        />

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="30px"
                        />

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="30px"
                        />

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="30px"
                        />

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="30px"
                        />
                      </div>
                      <br />
                      <span className="pq-attr">
                        {data.review
                          ? data.review.reviewer.first_name +
                            " " +
                            data.review.reviewer.last_name
                          : ""}
                        <br />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ca-logo">
                  <a
                    target="_blank"
                    href="https://www.consumeraffairs.com/debt_counsel/national-debt-relief.html"
                    rel="noopener noreferrer"
                  >
                    ConsumerAffairs Reviews
                  </a>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>

      <Row className="no-gutter main-footer freedebttrustlogos">
        <Col md={12}>
          <FreeDebtTrustLogosRow />
        </Col>
      </Row>

      <FreeDebtQuoteFooter />
    </Container>
  );
};
export default FreeDebtConsolidationLoanSavings;

const getReviews = async (setData) => {
  let baseurl = "/api/v1/careviews";
  const request_data = {
    url: baseurl,
    method: "POST",
  };

  const response = await axios({
    url: request_data.url,
    method: request_data.method,
  });
  console.log("check response", response.data.results);
  await setData({
    isReady: true,
    review: response.data.results[0],
  });
};
