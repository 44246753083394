import React from "react";
import { Row, Col } from "react-bootstrap";
import { Images } from "../../assets/img/Images.js";
import "./footer.style.scss";

const Footer = (props, context) => {
  return (
    <Row className="no-gutter footer">
      <Col md={12} className="content">
        <Col sm={6} md={6} lg={6} className="no gutter info">
          <div className="address">
            <div>National Debt Relief LLC</div>
            <div>180 Maiden Lane, 30th Floor</div>
            <div>New York, NY 10038</div>
          </div>
          <div className="phone">
            <div className="text">
              <a href="tel:8003009550">800-300-9550</a>
            </div>
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="btn btn-sm"
              href="mailto:success@nationaldebtrelief.com"
            >
              success@nationaldebtrelief.com
            </a>
          </div>
        </Col>
        <Col sm={6} md={6} lg={6} className="social">
          <div className="text">
            Follow Us
            <div className="icons">
              {Images.socialMedia.map((image, key) => (
                <a
                  key={key}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={image.href}
                >
                  <img key={key} className="img-fluid" src={image.src} alt="" />
                </a>
              ))}
            </div>
          </div>
        </Col>
      </Col>
      <Col sm={12} className="privacy">
        <div className="text">
          <div>
            © {new Date().getFullYear().toString()} National Debt Relief. All
            rights reserved.
          </div>
          <div>
            <a
           
              rel="noreferrer noopener"
              href="/privacypolicy"
            >
              Privacy Policies
            </a>
            &nbsp;|&nbsp;
            <a
          
              rel="noreferrer noopener"
              href="/termsofsite/"
            >
              Terms of Use
            </a>
            &nbsp;|&nbsp;
            <a rel="noreferrer noopener" href="/aboutus/">
              About Us
            </a>
          </div>
        </div>
      </Col>
      <Col sm={12} className="disclaimer">
        <div className="text">
          * Rated #1 for Debt Consolidation by TopConsumerReviews,
          TopTenReviews, ConsumersAdvocate and ConsumerAffairs
          <br />
          <br />
          Please note that all calls with the company may be recorded or
          monitored for quality assurance and training purposes.Clients who are
          able to stay with the program and get all their debt settled realize
          approximate savings of 50% before fees, or 30% including our fees,
          over 24 to 48 months. All claims are based on enrolled debts. Not all
          debts are eligible for enrollment. Not all clients complete our
          program for various reasons, including their ability to save
          sufficient funds. Estimates based on prior results, which will vary
          based on specific circumstances. We do not guarantee that your debts
          will be lowered by a specific amount or percentage or that you will be
          debt-free within a specific period of time. We do not assume consumer
          debt, make monthly payments to creditors or provide tax , bankruptcy,
          accounting or legal advice or credit repair services. Not available in
          all states. Please contact a tax professional to discuss tax
          consequences of settlement. Please consult with a bankruptcy attorney
          for more information on bankruptcy. Depending on your state, we may be
          available to recommend a local tax professional and/or bankruptcy
          attorney. Read and understand all program materials prior to
          enrollment, including potential adverse impact on credit rating.
        </div>
      </Col>
    </Row>
  );
};

export default Footer;
