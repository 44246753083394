/* eslint-disable   no-unused-vars */

import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header/header.component";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import Footer from "../components/footer/footer.component";
import "./Template.scss";
import "./Unbounce.scss";
import UnbounceFooter from "../components/footer/unbounce-footer.component";
import UnbounceHeader from "../components/header/unbounceheader.component";
const HowWeHelp = () => {
  return (
    <Container fluid className=" themeLayout full-width-content">
      <Row className="no-gutter header">
        <Col sm={12}>
        <UnbounceHeader/>
        </Col>
      </Row>
      <div className="no-gutter site-inner">
        <div className="content-sidebar-wrap">
          <div className="content">
            <div className="  article entry ">
              <div className="lp-pob-box">
                <div className="color-overlay">
                  <div
                    className="lp-pom-text title_secondary"
                    style={{ paddingTop: "15px", height: "108px" }}
                  >
                    <h1
                      className=""
                      style={{ textAlign: "center", lineHeight: "58px" }}
                    >
                      <span style={{ fontSize: "36px" }}>
                        <span
                          style={{
                            fontFamily: "Roboto",
                            fontWeight: "700",
                            fontStyle: "normal",
                            color: "rgb(24, 2, 122)",
                          }}
                        >
3 Easy Steps To Debt Consolidation

                        </span>
                      </span>
                    </h1>
                  </div>
                  <div className="lp-pom-text main-text" style={{ paddingTop: "15px" }}>

                  <ol>
                      <li style={{lineHeight: "20px"}}>
                          <span style={{fontWeight: "400" ,fontFamily: 'Roboto', fontSize: "18px", color: "rgb(39, 39, 39)",fontStyle: "normal"}}>
                              <strong>Fill out our debt assessment</strong> so we can understand your unique financial situation.<br/><br/></span></li>

                              <li style={{lineHeight: "20px"}}><span style={{fontWeight: "400", fontFamily: 'Roboto', fontSize: "18px" , color:"rgb(39, 39, 39)" ,fontStyle: "normal"}}><strong>Review your debt consolidation loan options</strong> with one of our certified debt specialists.<br/><br/></span></li>
                              <li style={{lineHeight: "20px"}}><span style={{fontWeight: "400", fontFamily: 'Roboto', fontSize: "18px", color: "rgb(39, 39, 39)", fontStyle: "normal"}}><strong>Receive your customized debt consolidation loan quote</strong> and see how much you can save!</span></li>
                              </ol>
                  </div>
                  <a
                    className=" lp-pom-button"
                    id="lp-pom-button-63"
                    href="/apply-loan"
                    target="_self"
                    data-params="true"
                  >
                    <span className="label">Start Your Free Debt Assessment</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UnbounceFooter/>
    </Container>
  );
};
export default HowWeHelp;
