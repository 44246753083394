import React from "react";

import PropTypes from "prop-types";

import TrustImage from '../../assets/img/trustpilot.png'
import BBBImage from '../../assets/img/bbb_logo.png'
import BestCompanyImage from '../../assets/img/best_company_logo.png'
import ConsumerAffairsImage from '../../assets/img/consumer_affairs_logo.png'
import GoogleImage from '../../assets/img/google_logo.png'

import "./review-icon.style.scss";

const ReviewIcon = ({ name }) => {
  const getIcon = () => {
    switch (name) {
    case 'Trustpilot':
      return TrustImage
    case 'Consumer Affairs':
      return ConsumerAffairsImage
    case 'Best Company':
      return BestCompanyImage
    case 'BestCompany':
      return BestCompanyImage
    case 'Google':
      return GoogleImage
    case 'BBB':
      return BBBImage
    default:
      return ''
  }}

  return <img className={`review-icon-image ${name.replace(/\s+/g, '-').toLowerCase()}`} src={getIcon()} alt={name} />
};

ReviewIcon.defaultProps = {
  name: null,
};

ReviewIcon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ReviewIcon;
