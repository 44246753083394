/* eslint-disable   no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "../components/header/header.component";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import Footer from "../components/footer/footer.component";
import "./Template.scss";

import fetchWPContent from "../api/wp-api";
const PrivacyPolicy = () => {
    const [content, setContent] = useState("");
    const [wptitle, setTitle] = useState("");
    const [isReady, setReady] = useState(false);

    const getContentRender = async (page) =>{
        console.log('test page', page);

const wpdata = await fetchWPContent(page);
console.log('check', wpdata);
if(wpdata){
    setContent(wpdata.render);
    setTitle(wpdata.title);
    setReady(true);
}
    }
    useEffect(() => {
  
        getContentRender('privacypolicy');
      
  
    }, []);

    return (
        <Container fluid className=" themeLayout full-width-content  template">
          <Row className="no-gutter site-header header">
            <Col sm={12}>
              <Header />
            </Col>
          </Row>
          <div className="no-gutter site-inner">
              <div className="content-sidebar-wrap">
              <div className="content">
            <div className="  article entry " >
            <header className="entry-header"><h1 className="entry-title">{wptitle?wptitle:null}</h1>
</header>
            <div
          className="entry-content"
          dangerouslySetInnerHTML={{ __html: content?content:null }}
        /> 
</div>
</div>
</div>
          </div>
    
          <Row className="no-gutter main-footer">
            <Col md={12}>
              <TrustLogosRow />
            </Col>
          </Row>
    
          <Footer />
        
        </Container>
      );
}
export default PrivacyPolicy;