/* eslint-disable   no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import { Images } from "../assets/img/Images.js";
import { Videos } from "../assets/videos/videos.component.js";

import Footer from "../components/footer/footer.component";
import StickyAside from "../components/sticky-aside/sticky-aside.component";
import SlideUpForm from "../components/slideup-form/slideup-form.component";
import Header from "../components/header/header.component";
import ReviewList from "../components/review-list/review-list.component";
import TrustLogosRow from "../components/trust-logos-row/trust-logos-row.component";
import VideoModal from "../components/video-modal/video-modal.component";

import fetchReviews from "../api/api";
import { useHistory } from "react-router-dom";

import Slider from "react-slick";
import ReactPlayer from "react-player";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVideo, setSlectedVideo] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [filteredReviews, setFilteredReviews] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  

  const toggle = () => setIsOpen((prevIsOpen) => !prevIsOpen);


  const handleVideoClick = ({ src, thumbnail }) => {
    setSlectedVideo({ src, thumbnail });

    toggle();
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <svg
      {...props}
      width="15"
      height="25"
      viewBox="0 0 15 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 24L1.424 12.874C1.37113 12.8271 1.3288 12.7695 1.29982 12.705C1.27083 12.6406 1.25585 12.5707 1.25585 12.5C1.25585 12.4293 1.27083 12.3594 1.29982 12.295C1.3288 12.2305 1.37113 12.1729 1.424 12.126L14 1"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <svg {...props} viewBox="0 0 15 25" fill="none">
      <path
        d="M1 24L13.576 12.874C13.6289 12.8271 13.6712 12.7695 13.7002 12.705C13.7292 12.6406 13.7442 12.5707 13.7442 12.5C13.7442 12.4293 13.7292 12.3594 13.7002 12.295C13.6712 12.2305 13.6289 12.1729 13.576 12.126L1 1"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const sliderSettings = {
    dots: false,
    infinite: false,
    lazyLoad: true,
    adaptiveHeight: true,
    slidesToShow: 4,
    arrows: true,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive:[
      {
        breakpoint: 960,
        settings:{
          slidesToShow:3,
          slidesToScroll: 1,

        }
      },
      {
        breakpoint: 768,
        settings:{
          slidesToShow:1,
          slidesToScroll: 1,

        }
      }
    ],
    nextArrow: <SlickArrowRight />,
    prevArrow: <SlickArrowLeft />,
  };

  const mobileSliderSettings = {
    ...sliderSettings,
    slidesToShow: 1,
  };

  const getReviews = async (filter) => {
    setLoading(true);
    const reviews = await fetchReviews({
      businessId: "159363930128461",
      filter,
    });
    if(reviews){
   setLoading(false);
   if (filter) {
  
    setReviews(reviews);
  
   setPageNumber(1);
  
    if (filter === "date") {
      setPageNumber(1);
    }

  }
  else{
    setReviews(reviews);
  }
    }
    
  };



  const paginate = useCallback(
    (pageSize = 10) => {
      const updatedReviews = reviews?.slice(
        (pageNumber - 1) * pageSize,
        pageNumber * pageSize
      );

      setFilteredReviews(updatedReviews);
    },
    [pageNumber, reviews]
  );
  
  const reviewsView = document.querySelector('.review-body');
  const handleItemClick = (pageNumber) =>{ 
   reviewsView.scrollIntoView();
    setPageNumber(pageNumber); 
  }

  const handleNextClick = () => {
    reviewsView.scrollIntoView();
    
    setPageNumber((prevPage) => prevPage + 1);}

  const handleLastClick = (lastPageNumber) => {
    reviewsView.scrollIntoView();
    setPageNumber(lastPageNumber);}

  const handleFirstClick = () => {
    reviewsView.scrollIntoView();
    setPageNumber(1);
  }

  const handlePrevClick = () => {
    reviewsView.scrollIntoView();
    setPageNumber((prevPage) => {
      if (pageNumber === 1) {
        return 1;
      } else {
        return prevPage - 1;
      }
    });
  };

  const onChangeFilter = ({ target }) => {
    const { value } = target;
    //setIsFilter(true);
    getReviews(value);
  };

  useEffect(() => {
  
      getReviews();
    

  }, []);

  useEffect(() => {
    if (pageNumber) {
      paginate();
    }
  }, [pageNumber, paginate]);

  return (
    <Container fluid className="App themeLayout homepage">
      <Row className="no-gutter site-header header">
        <Col sm={12}>
          <Header />
        </Col>
      </Row>
      <Row className="no-gutter hero">
        <Col className="d-flex justify-content-center" sm={12}>
        <Col className="no gutter" sm={12} md={8} lg={8}>
          <div className="main-image-container">
            <img alt="header" src={Images.header.src} />
            <div className="text-box">
              <h2 className="heading white">Client Testimonials & Reviews</h2>
              <div className="text">
                National Debt Relief has helped hundreds of thousands of clients
                find their way out of debt since 2009.
                <br></br><br></br>
                Scroll down to see all reviews.
              </div>
            </div>
          </div>
         
        </Col>
        <Col sm={4} className="d-flex justify-content-start aside-col">
        <StickyAside  />
        </Col>
    </Col>
      </Row>

      <Row className="no-gutter video-carousel">
        <Col sm={12}>
        <Col sm={12} md={8} lg={8}>
          <div className="video-carousel-container">
            <div className="slider-container desktop">
              <Slider {...sliderSettings}>
                {Videos.carousel.map(({ src, thumbnail, name }) => (
                  <div key={src} className="thumbnail-wrapper hvr-grow">
                    <div className="thumbnail">
                      <div
                        onClick={() => handleVideoClick({ src, thumbnail })}
                        className="video-container"
                      >
                        <ReactPlayer
                          className="video-player"
                          controls
                          light={thumbnail}
                          width="100%"
                          height="100%"
                          playIcon={
                            <img src={Images.PlayButton} alt={`play-${src}`} />
                          }
                          url={src}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="slider-container mobile">
              <Slider {...mobileSliderSettings}>
                {Videos.carousel.map(({ src, thumbnail, name }) => (
                  <div key={src} className="thumbnail-wrapper hvr-grow">
                    <div className="thumbnail">
                        <ReactPlayer
                          className="video-player"
                          playing
                          controls
                          light={thumbnail}
                          width="100%"
                          height="200px"
                          url={src}
                          playIcon={<img src={Images.PlayButton} alt={`play-${src}`} />}
                        />
                    </div>
                  <div className="mobile-video-text">{name}'s Video Testimonial</div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          </Col>
        </Col>
    
      </Row>

      <Row className="no-gutter review-body">
      <Col className="d-flex justify-content-center" sm={12}>
        <Col md={8} sm={12} lg={8}>
          <Container className="list-container">
            <div className="filter">
              <span className="filter-text">Filter By:</span>
              <select
                className={"filter-select"}
                name="filter"
                onChange={onChangeFilter}
              >
                <option value="date">Newest First</option>
                <option value="company">Company</option>
                <option value="BBB">BBB</option>
                <option value="BestCompany">Best Company</option>
                <option value="Google">Google</option>
              </select>
            </div>
            <ReviewList
              currentPageNumber={pageNumber}
              filteredReviews={filteredReviews}
              handleItemClick={handleItemClick}
              handleLastClick={handleLastClick}
              handleFirstClick={handleFirstClick}
              handleNextClick={handleNextClick}
              handlePrevClick={handlePrevClick}
              reviews={reviews}
              isLoading={isLoading}
            />
          </Container>
        </Col>
        <Col md={4} lg={4} sm={4}/>
        </Col>
        
      </Row>

      <Row className="no-gutter main-footer">
        <Col md={12}>
          <TrustLogosRow />
        </Col>
      </Row>

      <Footer />
      <SlideUpForm/>
      <VideoModal show={isOpen} handleClose={toggle} video={selectedVideo} />
    </Container>
  );
};

export default Home;
