/* eslint-disable   no-unused-vars */
/* eslint-disable   jsx-a11y/anchor-is-valid */
/* eslint-disable   jsx-a11y/alt-text */
/* eslint-disable     eqeqeq */
import React, { useEffect, useState, Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faLock, faStar } from "@fortawesome/free-solid-svg-icons";

import "../sidebar/TPReviews.scss";
import "../../pages/_get-out-of-debt.scss";
import Slider from 'react-slick';

const CAReviewsScroll = (props) => {
    const [data, setData] = useState({ isReady: false, reviewCount: null });


    const NextArrow = (props)=>{
        const {className, style, onClick} = props;
        return(
            <button type="button" onClick={onClick} className="slick-next" style={{display: "block"}}><span>Next</span><FontAwesomeIcon size="xs" icon={faChevronRight} className="fa fa-chevron-right" /></button>
        )

    }
    const PrevArrow = (props)=>{
        const {className, style, onClick} = props;
        return(
            <button type="button" onClick={onClick} className="slick-prev" style={{display: "block"}}><span>Previous</span>   <FontAwesomeIcon size="xs" icon={faChevronLeft}  className="fa fa-chevron-left"/></button>
        )
    }
    const settings={
    nextArrow:<NextArrow/>,
    prevArrow: <PrevArrow/>,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,

        }
      }
    ]
  }

  useEffect(() => {
    getReviewCount(setData);
  }, []);
  return (
   
<div className="wrap">
            <h2 className="fancy"><span>Real Client Reviews</span></h2>

            <div className="reviews-overview">
                <h3>Excellent</h3>
                <div className="five-star top">
                    <img width="30px" src="https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/trustpilot-star-rating.png"/>{" "}
                    <img width="30px" src="https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/trustpilot-star-rating.png"/>{" "}
                    <img width="30px" src="https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/trustpilot-star-rating.png"/>{" "}
                    <img width="30px" src="https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/trustpilot-star-rating.png"/>{" "}
                    <img width="30px" src="https://www.nationaldebtrelief.com/wp-content/themes/nationaldebtrelief/images/trustpilot-star-rating.png"/>
                </div>
                <p>
                    Based on <b>    {data.isReady == true && data.reviewCount}
    </b> reviews. <br/>
                    See some of the reviews here.
                </p>
                <a target="_blank" className="trust-pilot" href="">TrustPilot Reviews</a>
            </div>

            <style>{`
               .slick-slide img {
                    display: inline;
                }
                .slick-slide .five-star{
                    display: inline-block;
                }`
                }
            </style>

        
<div id="reviews">
            <Slider  className="slick-slider" {...settings}>
             <div className="ca-review">
             <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>
           <div className="quote-content-wrap">
                  <p className="quote-title">
                <strong>"I was so happy I called NDR"</strong></p>
             <p className="quote-body">I am drowning with debt now. I called NDR and I was so
                relief what they had to say. Thank god. Hoping to get help and
                just to not worry about anything. Cause I keep thinking about my
                debt. NDR will sure help me. THANK YOU SO MUCH.
              </p>
              </div>
            <div className="quote-author-meta">
              <span className="quote-author" >
                Redenthor of New Port Richey, FL
              </span></div>
           
            </div>

            <div className="ca-review">
            <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>
              <div className="quote-content-wrap">
              <p className="quote-title">
      
                <strong>"Friendly staff"</strong>
                </p>
             <p className="quote-body">
                The staff is friendly and willing to explain the whole process
                and steps. Also they answer every questions that you might have.
                The online portal is easy to use and user-friendly. Every topic
                that was discussed was emailed to me for my records. Thank
                You!!!
              </p>
</div>
<div className="quote-author-meta">
              <span className="quote-author" >
           
                Felix of Philadelphia, PA
            </span></div>
            </div>

            <div className="ca-review">
            <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>
              <div className="quote-content-wrap">
                  <p className="quote-title">
            
                <strong>"Debt relief"</strong></p>
             <p className="quote-body">
                Excellent customer service, very well explained on anything I
                had asked. I am very excited to becoming debt free. I was
                drowning and stressed out until I called National Debt Relief
                and they made me feel like everything was gonna be ok.
              </p></div>
             
              <div className="quote-author-meta">
              <span className="quote-author" >
                Friedaanne of Yakima, WA
          </span></div>
            </div>

            <div className="ca-review">
            <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>
              <div className="quote-content-wrap">
                  <p className="quote-title">
                <strong>
                 "Very helpful and nice makes me feel like I can breathe
                  again..."
                </strong></p>
                <p className="quote-body">
                Very helpful and nice, makes me feel like I can breathe again.
                Will definitely refer to anyone in need of debt relief. Great at
                explaining things what they're going to do, how they are gonna
                do it and how I will get the help I need.
                </p>
              </div>
            <div className="quote-author-meta">     <span className="quote-author" >
                Christopher of WAUKESHA, WI
                </span></div>
                </div>

            <div className="ca-review">
            <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>
              <div className="quote-content-wrap">
                  <p className="quote-title">
                <strong>"A+ to Jeff and Rosa"</strong></p>
                <p className="quote-body">I worked with Jeff who walked me through the process of
                the application and explained everything very professionally and
                efficiently. He really took his time and explained everything in
                detail and answered all of my questions. On my Welcome Call, I
                spoke with Rosa who also took her time and explained everything.
                Both of these people made the process very simple and were very
                efficient and professional. I was initially very nervous about
                this and they both made me feel very much at ease.
                </p>
              </div>
            <div className="quote-author-meta">
              <span className="quote-author" >
                Deborah of Knoxville, TN
                </span></div>
            </div>

            <div className="ca-review">
            <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>
              <div className="quote-content-wrap">
                  <p className="quote-title">
                <strong>Courteous and friendly</strong></p>
                <p className="quote-body">
                Courteous and friendly. Royce did a great job in explaining the
                program and I feel confident I will be taken care of. It feels
                good to be on the right path to get rid of debt and begin to
                save money instead of hemorrhage it. Thanks for your help,
                looking forward to writing another glaring review at the end of
                my program.
              </p>
             
              </div>
            <div className="quote-author-meta">
              <span className="quote-author" >
                Andrew of Aurora, CO
              </span>
            </div>
            </div>

            <div className="ca-review">
            <span className="five-star" style={{ marginBottom: "10px" }}>
              <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
{" "}
                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />{" "}

                        <img
                          alt="Trustpilot star rating"
                          src="https://ww3.nationaldebtrelief.com/static/ndr_app/images/trustpilot-star-rating.png"
                          width="20px"
                        />
              </span>

              <div className="quote-content-wrap">
                  <p className="quote-title">
                <strong>"Initial enrollment"</strong></p>
                <p className="quote-body">
                The level of knowledge, compassion and professionalism is
                outstanding. The representatives I dealt with took time to
                explain everything in a transparent manner. They were kind and
                not once passed judgment. I am looking forward to working with
                National Debt Relief.
              </p>
              </div>
              <div className="quote-author-meta">
              <span className="quote-author" >
                Maria of Yakima, WA
              </span>
           </div>
        </div>
      
     
                

                
            </Slider>
                
            </div>
                


        </div>


  );
};
export default CAReviewsScroll;

const getReviewCount = async (setData) => {
    let baseurl = "/api/v1/careviews";
    const request_data = {
      url: baseurl,
      method: "POST",
    };
  
    const response = await axios({
      url: request_data.url,
      method: request_data.method,
    });
  
    await setData({
      isReady: true,
      reviewCount: response.data.count
        .toString()
        .replace(/(\d)(?=(\d\d\d)+$)/, "$1,"),
    });
  };