import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import ThankYouApply from "./pages/ThankYouApply";
import ThankYouApplication from "./pages/ThankYouApplication";
import './App.scss'
import ApplyLoan from "./pages/ApplyLoan";
import DebtConsolidationLoanSavings from "./pages/DebtConsolidationLoanSavings";
import FreeDebtConsolidationLoanQuote from "./pages/FreeDebtConsolidationLoanQuote";
import LetsTalk from "./pages/LetsTalk";
import HowitWorks from "./pages/HowitWorks";
import HowWeHelp from "./pages/HowWeHelp";
import AboutUs from "./pages/AboutUs";
import GetOutofDebtLoan from "./pages/GetOutofDebtLoan";
import CostofDebtCalculator from "./pages/CostofDebtCalculator";
import ThankYouApplicationLoan from "./pages/ThankYouApplicationLoan";
import ThankYouApplyLoan from "./pages/ThankYouApplyLoan";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsofSite from "./pages/TermsOfSite";

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/apply-loan" component={ApplyLoan}/>
        <Route path="/lets-talk" component={LetsTalk}/>
        <Route path="/how-we-help" component={HowWeHelp}/>
        <Route path="/how-it-works" component={HowitWorks}/>
        <Route path="/aboutus" component={AboutUs}/>
        <Route path="/privacypolicy" component={PrivacyPolicy}/>
        <Route path="/termsofsite" component={TermsofSite}/>
        <Route path="/debt-consolidation-loan-savings" component={DebtConsolidationLoanSavings}/>      
        <Route path="/calculators/cost-of-debt-calculator/" component={CostofDebtCalculator}/>
        <Route path="/thank-you-for-applying" component={ThankYouApply}/>
        <Route path="/get-out-of-debt-loan" component={GetOutofDebtLoan}/>

        <Route path="/free-debt-consolidation-loan-quote" component={FreeDebtConsolidationLoanQuote}/>
        <Route path="/thank-you-for-your-application" component={ThankYouApplication}/>
        
        <Route path="/thank-you-loan-application" component={ThankYouApplyLoan}/>
        <Route path="/thank-you-for-your-loan-application" component={ThankYouApplicationLoan}/>
        
      </Switch>
    </Router>
  );
};

export default App;
