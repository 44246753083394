
/* eslint-disable   no-unused-vars */

/* eslint-disable   react-hooks/exhaustive-deps */
/* eslint-disable    jsx-a11y/iframe-has-title */
/* eslint-disable   eqeqeq */
/* eslint-disable  no-unused-vars */
/* eslint-disable   react/jsx-no-target-blank */
/* eslint-disable   jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import TPReviewsWidget from "../sidebar/TPReviewsWidget";
import { useHistory } from "react-router-dom";

import chevronIcon from "../../assets/img/chevron-right-solid-01.png";

const ApplyFormStep1 = (props) => {
  const { setStep, step, debitAmount, setDebitAmount } = props;

  const [isDebitAmountError, setDebitError] = useState(false);

  const onApplyNext = (event) => {
    event.preventDefault();

    let debtAmount = event.target.debtAmount.value;

    console.log("test event value", event.target.debtAmount.value);
    if (debtAmount != " ") {
      setStep("2");
      setDebitAmount(debtAmount);
    } else if (debtAmount == " ") {
      event.target.debtAmount.classList.add("invalid");
    }
  };
  const history = useHistory();
  useEffect(() => {
    const debtAmount = history.location.state
      ? history.location.state.debtAmount
      : null;
      console.log('get locationstate', history.location.state);
      
    if (debtAmount !== null) {
        document.getElementById("id_debt_amount").value = debtAmount;
      setDebitAmount(debtAmount);
    }
  }, []);

  return (
    <div className={`page-one ${step == "1" ? "show" : "hide"}`}>
      <div className={"apply_form_step_1_wrapper"}>
        <TPReviewsWidget />
        <div className="apply_form_title_wrapper">
          <h1 className="form_title_primary">
            Do You Qualify For Debt Consolidation
          </h1>
          <h2 className="form_title_secondary">Find Out Today</h2>
        </div>
        <div className="form_description_wrapper">
          <ul className="get-started-list">
            <li>Get A Free Consolidation Quote</li>
            <li>Get Out Of Debt Without Bankruptcy</li>
            <li>Be Debt Free In 24 To 60 Months</li>
            <li>No Upfront Fees and No Obligation</li>
          </ul>
        </div>
        <form id="applyStep1" onSubmit={onApplyNext}>
          <div className="apply_form_fields_wrapper">
            <label className="apply_form_label">
              <p>Get Started by Selecting Your Debt Amount:</p>
            </label>
            <div className="select_wrapper">
              <select
                name="debtAmount"
                className="get-started-form-input apply-form-select"
                id="id_debt_amount"
              >
                <option value={" "} defaultValue="1">
                  Select Your Amount
                </option>

                <option value="$0 - $4,999">$0 - $4,999</option>

                <option value="$5,000 - $7,499">$5,000 - $7,499</option>

                <option value="$7,500 - $9,999">$7,500 - $9,999</option>

                <option value="$10,000 - $14,999">$10,000 - $14,999</option>

                <option value="$15,000 - $19,999">$15,000 - $19,999</option>

                <option value="$20,000 - $29,999">$20,000 - $29,999</option>

                <option value="$30,000 - $39,999">$30,000 - $39,999</option>

                <option value="$40,000 - $49,999">$40,000 - $49,999</option>

                <option value="$50,000 - $59,999">$50,000 - $59,999</option>

                <option value="$60,000 - $69,99">$60,000 - $69,999</option>

                <option value="$70,000 - $79,999">$70,000 - $79,999</option>

                <option value="$80,000 - $89,999">$80,000 - $89,999</option>

                <option value="$90,000 - $99,999">$90,000 - $99,999</option>

                <option value="$100,000+">$100,000+</option>
              </select>
            </div>
          </div>
          <div className="apply_form_footer">
            <button type="submit" className="big-red-button --first">
              Continue
              <span className="fa-chevron-right" aria-hidden="true">
                <img src={chevronIcon} />
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplyFormStep1;
