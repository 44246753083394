import React from "react";

import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import ReviewIcon from "../review-icon/review-icon.component";

import StarIcon from "../../assets/img/star.png";

import "./review-list-item.style.scss";

const ReviewListItem = ({ review }) => {
  const {
    comments,
    rating,
    sourceType,
    reviewer,
  } = review;

  const checkComments = comments ? comments : "No Comments";

  const renderStars = () => {
    const starLength = [];

    for (let i = 0; i < rating; i++) starLength.push(i);

    return starLength.map((x, key) => <img key={key} src={StarIcon} alt="" />);
  };

  return (
    <Row>
      <Col sm={12}>
        <div
          className="list-item-content"
        >
          <div className="logo"><ReviewIcon name={sourceType} /></div>
          <div className="comments-container">
            <div className="ratings">
              <div className="reviewer-name">{reviewer?.firstName} {reviewer?.lastName}</div>
              <div>{renderStars()}</div>
            </div>
            <div className="comments">{checkComments}</div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

ReviewListItem.defaultProps = {
  review: null,
};

ReviewListItem.propTypes = {
  review: PropTypes.object.isRequired,
};

export default ReviewListItem;
