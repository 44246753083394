
/* eslint-disable   jsx-a11y/anchor-has-content */
/* eslint-disable   jsx-a11y/anchor-is-valid */
/* eslint-disable   eqeqeq */
/* eslint-disable  no-unused-vars */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faStar } from "@fortawesome/free-solid-svg-icons";
import lockIcon from "../../assets/img/lock-solid-white-01.png";
import "./TPReviews.scss";

const TPReviewsWidget = (props) => {
  const [data, setData] = useState({ isReady: false, reviewCount: null });

  useEffect(() => {
    getReviewCount(setData);
  }, []);
  return (
    <div className="trust-pilot-block">
      <div className="right-box-core right-box-core-trustpilot">
        <div className="right-box-middle">
          <div className="ca-reviews">
            <p className="ca-heading">
              {" "}
              <FontAwesomeIcon size="xs" icon={faLock} color="white" /> Trusted
              By Our Clients
            </p>
            <h2>Rated #1</h2>
            <span className="five-star" style={{ marginBottom: "5px" }}>
              {" "}
              <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
              <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
              <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
              <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
              <FontAwesomeIcon size="xs" icon={faStar} color="white" />
            </span>
            <p className="based-on">
              <span>Based on {data.isReady == true && data.reviewCount}</span>
              <br />
              REVIEWS
            </p>
            <div className="ca-review">
              <p>
                <strong>Debt consolidation</strong>
                <br />
                This process was relatively easy. No one wants to have to call
                and ask help but once you get past that and call, they don't
                treat you like you're less of a person for getting yourself into
                this position. I greatly appreciated that!
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Autumn of Cocoa, FL
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>NDR program</strong>
                <br />
                All associates with NDR have been professional and reassuring.
                As we enter the rest of the program, we'll see how the program
                really works and see how effective it is. Every step has been
                explained fully and I understand the process. I'm sure questions
                will arise and I hope the program works.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Anthony of Jacksonville, FL
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>I was so happy I called NDR</strong>
                <br />I am drowning with debt now. I called NDR and I was so
                relief what they had to say. Thank god. Hoping to get help and
                just to not worry about anything. Cause I keep thinking about my
                debt. NDR will sure help me. THANK YOU SO MUCH.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Redenthor of New Port Richey, FL
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>Friendly staff</strong>
                <br />
                The staff is friendly and willing to explain the whole process
                and steps. Also they answer every questions that you might have.
                The online portal is easy to use and user-friendly. Every topic
                that was discussed was emailed to me for my records. Thank
                You!!!
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Felix of Philadelphia, PA
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>Debt relief</strong>
                <br />
                Excellent customer service, very well explained on anything I
                had asked. I am very excited to becoming debt free. I was
                drowning and stressed out until I called National Debt Relief
                and they made me feel like everything was gonna be ok.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Friedaanne of Yakima, WA
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>
                  Very helpful and nice makes me feel like I can breathe
                  again...
                </strong>
                <br />
                Very helpful and nice, makes me feel like I can breathe again.
                Will definitely refer to anyone in need of debt relief. Great at
                explaining things what they're going to do, how they are gonna
                do it and how I will get the help I need.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Christopher of WAUKESHA, WI
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>A+ to Jeff and Rosa</strong>
                <br />I worked with Jeff who walked me through the process of
                the application and explained everything very professionally and
                efficiently. He really took his time and explained everything in
                detail and answered all of my questions. On my Welcome Call, I
                spoke with Rosa who also took her time and explained everything.
                Both of these people made the process very simple and were very
                efficient and professional. I was initially very nervous about
                this and they both made me feel very much at ease.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Deborah of Knoxville, TN
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>Courteous and friendly</strong>
                <br />
                Courteous and friendly. Royce did a great job in explaining the
                program and I feel confident I will be taken care of. It feels
                good to be on the right path to get rid of debt and begin to
                save money instead of hemorrhage it. Thanks for your help,
                looking forward to writing another glaring review at the end of
                my program.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Andrew of Aurora, CO
              </p>
            </div>

            <div className="ca-review">
              <p>
                <strong>Initial enrollment</strong>
                <br />
                The level of knowledge, compassion and professionalism is
                outstanding. The representatives I dealt with took time to
                explain everything in a transparent manner. They were kind and
                not once passed judgment. I am looking forward to working with
                National Debt Relief.
              </p>
              <span className="five-star" style={{ marginBottom: "5px" }}>
                {" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />{" "}
                <FontAwesomeIcon size="xs" icon={faStar} color="white" />
              </span>
              <p style={{ textAlign: "right", clear: "both" }}>
                Maria of Yakima, WA
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="consumer-affairs">
        <a
          href="#"
          target="_blank"
          title="this link will open in a new window"
        ></a>
      </div>
    </div>
  );
};
export default TPReviewsWidget;
const getReviewCount = async (setData) => {
  let baseurl = "/api/v1/careviews";
  const request_data = {
    url: baseurl,
    method: "POST",
  };

  const response = await axios({
    url: request_data.url,
    method: request_data.method,
  });

  await setData({
    isReady: true,
    reviewCount: response.data.count
      .toString()
      .replace(/(\d)(?=(\d\d\d)+$)/, "$1,"),
  });
};
