import ThumbnailOne from "../img/thumbnail_alex.jpg";
import ThumbnailTwo from "../img/thumbnail_thomas.png";
import ThumbnailThree from "../img/thumbnail_joe.jpg";
import ThumbnailFour from "../img/thumbnail_janice.jpg";
import ThumbnailFive from "../img/thumbnail_christopher.jpg";
import ThumbnailSix from "../img/thumbnail_kenneth.jpg";
import ThumbnailSeven from "../img/thumbnail_sharona.jpg";

export const Videos = {
	carousel: [
		{ thumbnail: ThumbnailOne, src: 'https://youtu.be/nPXYm5FIeU8', name: 'Alex'    },
		{ thumbnail: ThumbnailTwo, src: 'https://youtu.be/am215swodCI', name: 'Thomas'   },
		{ thumbnail: ThumbnailThree, src:'https://youtu.be/IEuGQTS7OqQ' , name: 'Joe'   },
		{ thumbnail: ThumbnailFour, src: 'https://youtu.be/RITYnuJg7Vs', name: 'Janice'   },
		{ thumbnail: ThumbnailFive, src: 'https://youtu.be/aNq9sgQNbw4', name: 'Christopher'   },
		{ thumbnail: ThumbnailSix, src: 'https://youtu.be/qfhFyP_FgJw', name:  'Kenneth'  },
		{ thumbnail: ThumbnailSeven, src: 'https://youtu.be/KwShVs7Pyto', name: 'Sharona'   }
	]
};