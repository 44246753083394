
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import { Form, Button, Row } from "react-bootstrap";
import MaskInput from "react-maskinput";
import "./slideup-form.style.scss";
import applySubmit from "../../api/apply-api";
import { useHistory } from "react-router-dom";
import { faAngleDown, faLock, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const SlideupForm = (props) => {
  const history = useHistory();
  const selectDebitAmount = (debitAmount) => {
    return debitAmount;
  };
  const submitForm = async (body) => {
    const submitResponse = await applySubmit(body);

    if (submitResponse.status === 200) {
     
      const debtValue = debitAmount.indexOf("+")>0 ? debitAmount.slice(1) : debitAmount.split(" - ")[1].slice(1);
      const over10K = parseInt(debtValue) > 9;

 

      const badState = submitResponse.message.target;
      if (!over10K) {
        history.push("/thank-you-for-your-application", {
          firstName: firstName,
        });
      } else if (badState) {
        history.push("/thank-you-for-your-application", {
          firstName: firstName,
        });
      } else {
        history.push("/thank-you-for-applying", { firstName: firstName });
      }
    }
  };

  const handleApplySubmit = (e) => {
    e.preventDefault();

  
   

    if (validate()) {
      var tcpainput = document.querySelector(
        'input[name$="xxTrustedFormCertUrl"]'
      );
      var tcpaurl = tcpainput ? tcpainput.value : "";
      var body = JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone,
        email_address: email,
        debt_amount: debitAmount,
        url_of_page: window.location.href,
        web_referrer: document.referrer || "",
        cookie_enabled: navigator.cookieEnabled ? "true" : "false",
        tcpa: true,
        xxTrustedFormToken: tcpaurl,
        xxTrustedFormCertUrl: tcpaurl,
      });
      submitForm(body);
    }
  };

  const [debitAmount, setDebitAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [isFirstNameError, setFirstNameError] = useState(false);
  const [isLastNameError, setLastNameError] = useState(false);
  const [isDebitAmountError, setDebitError] = useState(false);
  const [isPhoneError, setPhoneError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);

  const validate = () => {
    let errorFound = false;
    if (debitAmount === "") {
      setDebitError(true);
      errorFound = true;
    } else {
      setDebitError(false);
    }

    if (firstName === "") {
      setFirstNameError(true);
      errorFound = true;
    } else {
      setFirstNameError(false);
    }

    if (lastName === "") {
      setLastNameError(true);
      errorFound = true;
    } else {
      setLastNameError(false);
    }

    if (!validator.isMobilePhone(phone, "en-US")) {
      setPhoneError(true);
      errorFound = true;
    } else {
      setPhoneError(false);
    }

    if (!validator.isEmail(email)) {
      setEmailError(true);
      errorFound = true;
    } else {
      setEmailError(false);
    }

    return !errorFound;
  };
  const handleSlideupForm = () => {
    const slideupFormTitle = document.querySelector(".slideup-form-title");

    if (slideupFormTitle) {
      const slideupFormWrap = document.querySelector(".slideup-form-wrap");
      const slideupFormMobile = document.querySelector(".slideup-mobile-form");

      if (slideupFormMobile.classList.contains("active-slider-form")) {
        slideupFormMobile.classList.remove("active-slider-form");

        setTimeout(function () {
          slideupFormWrap.classList.add("unselectable");
        }, 500);
      } else {
        slideupFormMobile.classList.add("active-slider-form");
        setTimeout(function () {
          slideupFormWrap.classList.remove("unselectable");
        }, 500);
      }
    }
  };

  useEffect(() => {
    const slideupFormTitle = document.querySelector(".mobile-toggle");
    if (slideupFormTitle) {
      slideupFormTitle.addEventListener("click", handleSlideupForm);
      return () =>
        slideupFormTitle.removeEventListener("click", handleSlideupForm);
    }
  });

  return (
    <Row className="no-gutter slideup-mobile-form">
      <div className="slideup-form-title">
        <div className="slideup-toggle">
          <Button type="submit" className="get-started-form-submit">
            Get Free Quote!
          </Button>
          <span className="mobile-toggle">
            <FontAwesomeIcon size="xs" icon={faAngleDown} color="white" />
          </span>
          <h3>
            <em>Get started with a</em>
            <br />
            <strong>FREE </strong> Debt Relief Quote
          </h3>
        </div>

        <div className="slideup-form-wrap unselectable">
          <Form className="mobile-get-started-form">
            <Form.Group controlId="formGroupFirstName">
              <Form.Control
                type="text"
                placeholder="First Name"
                className={
                  isFirstNameError ? "get-started-form-input-invalid" : null
                }
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupLastName">
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={lastName}
                className={
                  isLastNameError ? "get-started-form-input-invalid" : null
                }
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupPhone">
              <MaskInput
                className={
                  isPhoneError ? "get-started-form-input-invalid" : null
                }
                size={20}
                maskChar="_"
                showMask
                mask={"(000)000-0000"}
                placeholder="Phone Number"
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formGroupEmail">
              <Form.Control
                className={
                  isEmailError ? "get-started-form-input-invalid" : null
                }
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder="Email Address"
              />
            </Form.Group>
            <Form.Group controlId="formGroupSelect">
              <Form.Control
                as="select"
                className={
                  isDebitAmountError ? "get-started-form-input-invalid" : null
                }
                onChange={(e) =>
                  setDebitAmount(selectDebitAmount(e.target.value))
                }
                defaultValue="Enter Your Debt Amount"
              >
                <option>Enter Your Debt Amount</option>
                <option>$0 - $4,999</option>
                <option>$5,000 - $7,499</option>
                <option>$7,500 - $9,999</option>
                <option>$10,000 - $14,999</option>
                <option>$15,000 - $19,999</option>
                <option>$20,000 - $29,999</option>
                <option>$30,000 - $39,999</option>
                <option>$40,000 - $49,999</option>
                <option>$50,000 - $59,999</option>
                <option>$60,000 - $69,999</option>
                <option>$70,000 - $79,999</option>
                <option>$80,000 - $89,999</option>
                <option>$90,000 - $99,999</option>
                <option>$100,000+</option>
              </Form.Control>
              <FontAwesomeIcon size="xs" icon={faCaretDown} color="black" />

            </Form.Group>
            <Button type="submit" onClick={(e) => handleApplySubmit(e)}>
              Get Free Quote!
            </Button>
            <p>
              <span className="form-disclaimer">
                By submitting, you agree that the phone number you are providing
                may be used to contact you by National Debt Relief (including
                autodialed and prerecorded calls or text/SMS messages). Msg. and
                data rates apply.
              </span>
            </p>
          </Form>
        </div>

        <div className="bbb-block">
          <div>
            <p className="lock">
              <FontAwesomeIcon size="xs" icon={faLock} color="white" /> Your
              information is Secure
            </p>

            <a
              className="bbb-form"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bbb.org/us/ny/new-york/profile/credit-and-debt-counseling/national-debt-relief-llc-0121-110899#bbbonlineclick"
            >
              <img
                alt="National Debt Relief, LLC BBB Business Review"
                src="https://seal-newyork.bbb.org/seals/blue-seal-250-52-national-debt-relief-llc-110899.png"
              />
            </a>
          </div>
        </div>
      </div>
    </Row>
  );
};
export default SlideupForm;
